import {FETCH_SALES_PEOPLE, LOADING_SALES_PEOPLE} from "./actions";

const salesPeopleState = {
    sales_people: null,
};

const reducer = (state = salesPeopleState, action) => {
    if (action.type === FETCH_SALES_PEOPLE) {
        return {...state, sales_people: action.sales_people, loading: false};
    }
    if (action.type === LOADING_SALES_PEOPLE) {
        return {...state, loading: true};
    }
    return state;
};

export default reducer;