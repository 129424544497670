import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../helpers/helper_functions";

export const FETCH_CLIENTS = "fetch_clients";
export const LOADING_CLIENTS = "loading_clients";

export const getClients = () => {
    return dispatch => {
        dispatch({
            type: LOADING_CLIENTS
        })
        makeRequest(GET_REQUEST, `${endpoints.clients}`, {}, response => {
            const clients = response.data;
            return dispatch({
                clients: clients,
                type: FETCH_CLIENTS
            })
        }, error => {
            showAlert('error', "Error!", parseErrorResponse(error));
            console.log(error);
        });
    }
}