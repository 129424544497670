import React, {Component} from 'react';
import {makeRequest} from "../../../helpers/network_utils";
import {POST_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import Swal from "sweetalert2";
import {handleChangeData} from "../../../helpers/helper_functions";

const $ = window.$;

class UserAdd extends Component {
    state = {
        name: '',
        data: {},
        loading: false,
        errors: {}
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        })
        const {data} = this.state;
        makeRequest(POST_REQUEST, endpoints.users, data, () => {
            Swal.fire(
                'Success!',
                'User was saved successfully!',
                'success'
            ).then(() => {
                this.props.getUsers();
                $("#addUserModal").modal('toggle');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
            });
        }, (error) => {
            this.setState({
                errors: error.response.data
            })
        }, () => {
            this.setState({loading: false})
        })
    }

    render() {
        const {errors, loading} = this.state;
        return (
            <div className="modal fade" id="addUserModal" role="dialog" aria-labelledby="exampleModalCenterTitle"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Add User</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">First Name</label>
                                        <input onChange={(e) => handleChangeData(e, this)} type="text"
                                               className="form-control" name="first_name"/>
                                        {errors.first_name &&
                                        <p className="small text-danger">{errors.first_name[0]}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Last Name</label>
                                        <input onChange={(e) => handleChangeData(e, this)} type="text"
                                               className="form-control" name="last_name"/>
                                        {errors.last_name && <p className="small text-danger">{errors.last_name[0]}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Email</label>
                                        <input onChange={(e) => handleChangeData(e, this)} type="text"
                                               className="form-control" name="email"/>
                                        {errors.email && <p className="small text-danger">{errors.email[0]}</p>}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button onClick={this.handleSubmit} type="button" className="btn btn-primary"
                                    disabled={loading}>{loading ? "Loading..." : "Save"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default UserAdd;