import {GET_REQUEST, JWT_TOKEN, ORGANIZATION_DATA, REFRESH_TOKEN, SUBDOMAIN_NAME, USER_OBJECT} from "../values/globals";
import {makeRequest} from "./network_utils";

export const getToken = function () { //
    return sessionStorage.getItem(JWT_TOKEN);
};

export const saveToken = function (token) {
    sessionStorage.setItem(JWT_TOKEN, token);
};

//TODO: This should be removed
export const saveRefreshToken = function (token) {
    sessionStorage.setItem(REFRESH_TOKEN, token);
};

//TODO: This should be removed
export const getRefreshToken = function () {
    return sessionStorage.getItem(REFRESH_TOKEN);
};

export const isUserLoggedIn = function () {
    return !(sessionStorage.getItem(USER_OBJECT) === null ||
        sessionStorage.getItem(USER_OBJECT) === undefined ||
        sessionStorage.getItem(USER_OBJECT) === 'null' || sessionStorage.getItem(USER_OBJECT) === 'undefined')
};

export const setUserObject = function (beforeFunc, afterFunc) {
    if (beforeFunc) {
        beforeFunc();
    }
    makeRequest(GET_REQUEST, "auth/users/me/", {}, function (response) {
        let obj = response.data;
        sessionStorage.setItem(USER_OBJECT, JSON.stringify(obj));
    }, error => {
        console.log(error.response);
    }, () => {
        if (afterFunc) {
            afterFunc();
        }
    });

};
export const getUserObject = function () {
    return JSON.parse(sessionStorage.getItem(USER_OBJECT));

};
export const logout = function () {
    sessionStorage.removeItem(USER_OBJECT);
    sessionStorage.removeItem(JWT_TOKEN);
    sessionStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(SUBDOMAIN_NAME);
    localStorage.removeItem(ORGANIZATION_DATA);
    //Redirect the user to the login page.
    window.location.href = "/login";
};
