import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../helpers/helper_functions";

export const FETCH_SALES_PEOPLE = "fetch_sales_people";
export const LOADING_SALES_PEOPLE = "loading_sales_people";

export const getSalesPeople = () => {
    return dispatch => {
        dispatch({
            type: LOADING_SALES_PEOPLE
        })
        makeRequest(GET_REQUEST, `${endpoints.sales_people}`, {}, response => {
            const sales_people = response.data;
            return dispatch({
                sales_people: sales_people,
                type: FETCH_SALES_PEOPLE
            })
        }, error => {
            showAlert('error', "Error!", parseErrorResponse(error));
            console.log(error);
        });
    }
}