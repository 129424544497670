import React from "react";
import {Link} from "react-router-dom";

export const vehicleRequestColumns = [
    {
        dataField: 'name',
        text: 'Name',
    },
    {
        dataField: 'phone_number',
        text: 'Phone Number',
        //formatter: numberPlate => `${numberPlate || "-"}`
    },
    {
        dataField: 'email',
        text: 'Email',
        //formatter: numberPlate => `${numberPlate || "-"}`
    },
    {
        dataField: 'area',
        text: 'Area',
    },
    {
        dataField: 'number_plate',
        text: 'Registration Number',
    },
    {
        dataField: 'id',
        text: 'Action',
        formatter: id => <Link to={`/vehicle-requests/${id}`} className="btn btn-sm btn-success">View</Link>
    },
];