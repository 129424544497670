import React, {Component} from "react";
import Totals from "./totals";
import Vehicles from "./vehicles";

class Dashboard extends Component {
    render() {
        return (
            <div>
                <Totals/>
                {/* <ExpensesStats/>*/}
                {/*<Expenses trim={true}/>*/}
                <Vehicles/>
            </div>
        );
    }
}

export default Dashboard;