import React, {Component} from 'react';
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../../components/Table";
import FeaturesAdd from "./add";
import {getFeatures} from "../../../redux/features/actions";
import Swal from "sweetalert2";
import {makeRequest} from "../../../helpers/network_utils";
import {DELETE_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../../helpers/helper_functions";

const $ = window.$;

const mapStateToProps = state => (state.featuresState);

const mapDispatchToProps = dispatch => ({
    getFeatures: () => dispatch(getFeatures()),
});

let self;
const columns = [
    {
        dataField: 'action',
        text: '#',
        formatter: (field, row, index) => index + 1
    },
    {
        dataField: 'name',
        text: 'Name',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'id',
        text: 'Action',
        formatter: field => <button onClick={e => {
            console.log(field);
            e.preventDefault();
            $('#addFeatureModal').modal('show');
            self?.setState({id: field})
        }} className="btn btn-sm btn-info">Edit</button>
    },
    {
        dataField: 'action_delete',
        text: 'Action',
        formatter: (field, row) => <button disabled={self.state.loadingDelete} onClick={() => self.handleDelete(row.id)}
                                           className="btn btn-sm btn-danger">{self.state.loadingDelete ? "Loading" : "Delete"}</button>
    },
]


class Features extends Component {
    state = {id: 0}

    componentDidMount() {
        self = this;
        const {getFeatures, features} = this.props;
        if (features == null) getFeatures();
    }

    handleAddFeature = e => {
        e.preventDefault();
        $('#addFeatureModal').modal('show');
    }

    handleDelete = (id) => {
        Swal.fire({
                title: 'Information!',
                text: 'Are you sure you want delete this?',
                icon: 'info',
                showCancelButton: true,
                cancelButtonText: 'Cancel'
            }
        ).then(result => {
            if (result.isConfirmed) {
                this.setState({
                    loadingDelete: true
                })
                makeRequest(DELETE_REQUEST, `${endpoints.features}${id}`, {}, () => {
                    Swal.fire(
                        'Success!',
                        'Feature deleted successfully!',
                        'success',
                    ).then(() => this.props.getFeatures());
                }, error => {
                    showAlert('error', 'Error', parseErrorResponse(error))
                }, () => {
                    this.setState({
                        loadingDelete: false
                    })
                })
            }

        });
    }

    render() {
        const {loading, features} = this.props;
        const {id} = this.state;
        return (loading ?
                <div className="text-center">
                    <CircularProgress/>
                </div> :
                <div>
                    <FeaturesAdd id={id} getFeatures={this.props.getFeatures}/>
                    <Table
                        caption={"Features"}
                        actionText={"Add Feature"}
                        columns={columns}
                        actionFunc={this.handleAddFeature}
                        data={features}
                    />
                </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Features);