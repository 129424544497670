import React, {Component} from 'react';
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../../components/Table";
import SuppliersAdd from "./add";
import {getSuppliers} from "../../../redux/suppliers/actions";

const $ = window.$;

const mapStateToProps = state => ({
    suppliers: state.suppliersState.suppliers,
    loading: state.suppliersState.loading
});

const mapDispatchToProps = dispatch => ({
    getSuppliers: () => dispatch(getSuppliers()),
});

const columns = [
    {
        dataField: 'id',
        text: 'ID',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'name',
        text: 'Name',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'country',
        text: 'Country',
        //formatter: row => `${row?.name ?? "-"}`
    },
]


class Suppliers extends Component {
    componentDidMount() {
        const {getSuppliers, suppliers} = this.props;
        if (suppliers == null) getSuppliers();
    }

    handleAddModel = e => {
        e.preventDefault();
        $('#addSupplierModal').modal('show');
    }

    render() {
        const {loading, suppliers, getSuppliers} = this.props;
        return (loading ?
                <div className="text-center">
                    <CircularProgress/>
                </div> :
                <div>
                    <SuppliersAdd getSuppliers={getSuppliers}/>
                    <Table
                        caption={"Suppliers"}
                        actionText={"Add Supplier"}
                        columns={columns}
                        actionFunc={this.handleAddModel}
                        data={suppliers}
                    />
                </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Suppliers);