import React, {Component} from 'react';
import UsersList from "./users";

class AdminSettings extends Component {

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <UsersList/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminSettings;