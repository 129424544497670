import React, {Component} from 'react';
import {onMount} from "../../providers/loader_plain";
import endpoints from "../../routes/endpoints";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../components/Table";
import {enquiryColumns} from "../../providers/enquiries/data";
import VehicleDetails from "../vehicle/details";
import {makeRequest} from "../../helpers/network_utils";
import {PATCH_REQUEST} from "../../values/globals";

class EnquiryDetails extends Component {
    state = {
        enquiry: null
    }

    componentDidMount() {
        this.getTradeInRequests();
        this.markAsRead();
    }

    getTradeInRequests = () => {
        const {id} = this.props.match.params;
        onMount(this, `${endpoints.enquiries}${id}`, "enquiry");
    }

    markAsRead = () => {
        const {id} = this.props.match.params;
        makeRequest(PATCH_REQUEST, `${endpoints.enquiries}${id}/`, {read: true});
    }

    render() {
        let {
            loading,
            enquiry
        } = this.state;
        let {
            match,
            location,
            history
        } = this.props;
        if (enquiry)
            match.params.id = enquiry.vehicle;
        return (
            loading ?
                <div className="text-center my-5 py-5">
                    <CircularProgress/>
                </div> :
                enquiry ?
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <Table
                                    caption={"Enquiry"}
                                    hideAction={true}
                                    actionText={"Add sales_request"}
                                    columns={enquiryColumns(true)}
                                    data={[enquiry]}
                                />
                            </div>
                        </div>
                        <div className="card my-3">
                            <div className="card-body">
                                <h3>Message</h3>
                                <p>{enquiry?.text}</p>
                            </div>
                        </div>
                        <VehicleDetails match={match} location={location} history={history}/>
                    </div> :
                    <div className="text-center py-5 my-5">
                        <p className="font-weight-bold">Could not load enquiry</p>
                    </div>
        );
    }
}

export default EnquiryDetails;