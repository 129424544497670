import React from "react";

function SingleImage(props) {
  const {image, handleClick, index, onImagePositionChange, images} = props;
  return (
    <div className="col-md-4">
      <div className="card card-shadow mb-4 bg-white text-black">
        <div className="card-body">
          <div className="row d-flex align-items-center">
            <div className="col-12 text-center">
              <img onClick={e => handleClick(e, index)} style={{cursor: "zoom-in"}}
                   className="imi d-inline-block mb-3 mb-lg-0 img-responsive" src={image.image}
                   srcSet={image.image} alt="car"/>
              <div className="form-group">
                <label>Drive Type <b className="text-danger">*</b></label>
                <select name="drive_type"
                        value={index}
                        onChange={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          onImagePositionChange(e.target.value, image)
                        }}
                        className="form-control">
                  {images.map((_, index) => (
                    <option key={index} value={index}>{index + 1}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleImage;