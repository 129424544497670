import React, {Component} from 'react';
import {makeRequest} from "../../../helpers/network_utils";
import {PATCH_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import Swal from "sweetalert2";
import {getUserObject} from "../../../helpers/login";
import {handleChangeData} from "../../../helpers/helper_functions";

const $ = window.$;

class EditProfile extends Component {

    state = {
        data: getUserObject(),
        loading: false,
        errors: {}
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data !== this.props.data)
            this.setState({
                data: getUserObject()
            })
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        })
        const {data} = this.state;
        makeRequest(PATCH_REQUEST, `${endpoints.me}`, data, () => {
            Swal.fire(
                'Success!',
                `Your details were edited successfully!`,
                'success'
            ).then(() => {
                this.props.getUser();
                $("#editProfileModal").modal('toggle');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
            });
        }, (error) => {
            this.setState({
                errors: error.response.data
            })
        }, () => {
            this.setState({loading: false})
        })
    }

    render() {
        const {data, errors, loading} = this.state;
        return (
            <div className="modal fade" id="editProfileModal" role="dialog" aria-labelledby="exampleModalCenterTitle"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Add Feature</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">First Name</label>
                                        <input
                                            value={data.first_name}
                                            onChange={(e) => handleChangeData(e, this)} type="text"
                                            className="form-control" name="first_name"/>
                                        {errors.first_name &&
                                        <p className="small text-danger">{errors.first_name[0]}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Last Name</label>
                                        <input
                                            value={data.last_name}
                                            onChange={(e) => handleChangeData(e, this)} type="text"
                                            className="form-control" name="last_name"/>
                                        {errors.last_name && <p className="small text-danger">{errors.last_name[0]}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Email</label>
                                        <input
                                            value={data.email}
                                            onChange={(e) => handleChangeData(e, this)} type="text"
                                            className="form-control" name="email"/>
                                        {errors.name && <p className="small text-danger">{errors.email[0]}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Phone Number</label>
                                        <input
                                            value={data.phone_number}
                                            onChange={(e) => handleChangeData(e, this)} type="text"
                                            className="form-control" name="name"/>
                                        {errors.phone_number &&
                                        <p className="small text-danger">{errors.phone_number[0]}</p>}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button onClick={this.handleSubmit} type="button" className="btn btn-primary"
                                    disabled={loading}>{loading ? "Loading..." : "Save"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditProfile;