import React, {Component} from 'react';
import BasicInfo from "./basic_info";
import Sales from "./sale/sale";
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST, PATCH_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../../helpers/helper_functions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Payments from "./payments";
import Swal from "sweetalert2";
import VehiclePhotos from "./photos";


const pages = {
  basic_info: "basic_info", photos: "photos", /*import_charges: "import_charges",*/
  /* yard_expenses: "yard_expenses",*/
  /*documents: "documents",*/
  sale: "sale", payments: "payments"
}

class VehicleDetails extends Component {

  state = {
    current_page: pages.payments,
    vehicle: null,
    loading: false,
    loadingPosition: false,
    loadingFeatured: false,
    images: [],
    image_index: -1
  }

  componentDidMount() {
    this.getImages();
    this.getVehicle();
  }

  getVehicle = () => {
    const {id} = this.props.match.params;
    this.setState({loading: true})
    makeRequest(GET_REQUEST, `${endpoints.vehicles}${id}/`, {}, response => {
      let vehicle = response.data;
      vehicle.bought = vehicle.sale != null;
      this.setState({
        vehicle: vehicle, current_page: response.data?.bought ? pages.payments : pages.basic_info
      })
    }, error => {
      showAlert('error', 'Error', parseErrorResponse(error))
    }, () => {
      this.setState({
        loading: false
      })
    })
  }

  getImages = () => {
    const {id} = this.props.match.params;
    this.setState({loading: true})
    makeRequest(GET_REQUEST, `${endpoints.vehicle_images}?vehicle__slug=${id}`, {}, response => {
      this.setState({
        images: response.data
      })
    }, error => {
      showAlert('error', 'Error', parseErrorResponse(error))
    }, () => {
      this.setState({
        loading: false
      })
    })
  }

  handleSell = (sold = true) => {
    const soldOrNot = sold ? "not sold" : "sold"
    Swal.fire({
      title: `Mark Vehicle as ${soldOrNot}`,
      text: `Are you sure you want to mark this vehicle as ${soldOrNot}?`,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      allowOutsideClick: () => !Swal.isLoading()
    }).then(result => {
      if (result.isDismissed) return;
      Swal.fire({
        title: 'Mark as Sold',
        html: "Marking as sold...",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: () => !Swal.isLoading()
      }).then();

      const {id} = this.props.match.params;
      makeRequest(PATCH_REQUEST, `${endpoints.vehicles}${id}/`, {
        sold: !sold
      }, () => {
        showAlert('success', 'Success!', 'Vehicle was marked successfully');
        this.getVehicle();
      }, error => {
        showAlert('error', 'Error!', parseErrorResponse(error));
      }, () => {
        Swal.hideLoading();
      })


    })
  }

  markAsFeatured = e => {
    const checked = e.target.checked;
    const {vehicle} = this.state;
    this.setState({
      loadingFeatured: true
    })
    makeRequest(PATCH_REQUEST, `${endpoints.vehicles}${vehicle.slug}/`, {featured: checked}, response => {
      this.setState({
        vehicle: {...vehicle, featured: response.data.featured}
      })
    }, error => {
      showAlert('error', 'Error', parseErrorResponse(error))
    }, () => {
      this.setState({
        loadingFeatured: false
      })
    })
  }

  handlePositionChange = (index, image) => {
    const {images} = this.state;
    const imagesCopy = [...images];

    // delete existing image from list
    const newImages = []
    for (const im of imagesCopy) {
      if (im.id !== image.id) {
        newImages.push(im)
      }
    }

    // set the image to the index.
    newImages.splice(index, 0, image)

    // set correct ranks
    for (let i = 0; i < newImages.length; i++) {
      newImages[i].rank = i + 1;
    }
    console.log(newImages)
    this.setState({
      images: newImages,
    })
  }

  handleSaveImagePositions = () => {
    this.setState({
      loadingPosition: true
    })
    const {images} = this.state;
    const imagesCopy = [...images];
    for (const img of imagesCopy) {
      makeRequest(PATCH_REQUEST, `${endpoints.vehicle_images}${img.id}/`, {
        rank: img.rank
      }, () => {
        Swal.fire({
          title: 'Success!', text: `Position Changed Successfully`, icon: 'success', confirmButtonText: 'Ok'
        }).then(() => {
        })
      }, (error) => {
        Swal.fire({
          title: 'Error!',
          text: `The images could not be updated: "${parseErrorResponse(error)}`,
          icon: 'error',
          confirmButtonText: 'Ok'
        }).then(() => {
        })
      }, () => {
        this.setState({
          loadingPosition: false
        })
      })
    }
  }

  render() {
    const {current_page, vehicle, images, loading, loadingPositions, loadingFeatured} = this.state;
    const {history, match} = this.props;

    return (

      loading ? <div className="text-center">
        <CircularProgress size={"5rem"}/>
      </div> : <div className="row">

        <div className="col-xl-3">
          <div className="card card-primary card-outline">
            <div className="card-body">
              {/* <Sell getVehicle={this.getVehicle} vehicle={vehicle}/>*/}
              <div style={{height: "30vh"}} id="carousel"
                   className="carousel slide bg-gray text-center"
                   data-ride="carousel">
                <div className="carousel-inner">
                  {images.length < 1 ? <img style={{objectFit: "contain", height: "30vh"}}
                                            className="imi d-inline-block mb-3 mb-lg-0 img-fluid"
                                            src="/assets/img/404.png " srcSet="
                                            /assets/img/404.png
                                       " alt="car"/> : images.map((image, index) => <div key={image.id}
                                                                                         className={`carousel-item ${index === 0 && "active"}`}>
                    <img style={{objectFit: "cover", height: "30vh", width: "100%"}}
                         className="imi d-inline-block mb-3 mb-lg-0 img-fluid"
                         src={image.image} srcSet={image.image} alt="car"/>
                  </div>)}
                </div>
                <a className="carousel-control-prev" href="#carousel" role="button"
                   data-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"/>
                  <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carousel" role="button"
                   data-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"/>
                  <span className="sr-only">Next</span>
                </a>
              </div>
              <div className="text-center my-2">
                <button onClick={() => {
                  history.push(`/vehicles/images/add/${vehicle.slug}`)
                }} className="btn btn-sm btn-success">
                  Add Images
                </button>
              </div>
              <ul className="list-group list-group-unbordered mb-3">
                <li className="list-group-item">
                  <b>Make: </b> <span
                  className="float-right">{vehicle?.model?.make?.name ?? "-"}</span>
                </li>
                <li className="list-group-item">
                  <b>Model: </b> <span
                  className="float-right">{vehicle?.model?.name ?? "-"}</span>
                </li>
                <li className="list-group-item">
                  <b>Year: </b> <span className="float-right">{vehicle?.year ?? "-"}</span>
                </li>
                <li className="list-group-item">
                  {loadingFeatured ? <div><CircularProgress/></div> : <div className="form-group form-check">
                    <input type="checkbox"
                           onChange={this.markAsFeatured}
                           checked={vehicle?.featured || false}
                           className="form-check-input" id="exampleCheck1"/>
                    <label className="form-check-label font-weight-bold"
                           htmlFor="exampleCheck1">Featured</label>
                  </div>}
                </li>
              </ul>

            </div>
          </div>
        </div>
        <div className="col-xl-9">
          <div className="card">
            <div className="card-header p-2">
              <ul className="nav nav-pills">
                <li className="nav-item">
                  <a className={`nav-link ${!vehicle?.bought && "active"}`} href="/#"
                     onClick={e => {
                       e.preventDefault();
                       this.setState({
                         current_page: pages.basic_info
                       })
                     }}
                     data-toggle="tab">Basic Info</a>
                </li>

                <li className="nav-item">
                  <a className={"nav-link"} href="/#"
                     onClick={e => {
                       e.preventDefault();
                       this.setState({
                         current_page: pages.photos
                       })
                     }}
                     data-toggle="tab">Photos</a></li>
                <div className="d-flex justify-content-end flex-md-fill">
                  <li className="nav-item mx-2">
                    <button className={"btn btn-info btn-sm mt-1"} onClick={this.handleSaveImagePositions}>
                      Save Positions
                    </button>
                  </li>
                  {<li className="nav-item mx-2">
                    <button className={"btn btn-success btn-sm mt-1"}
                            onClick={() => this.handleSell(vehicle?.sold)}
                            data-toggle="tab">{vehicle?.sold ? "Mark as Not Sold" : "Marks as Sold"}
                    </button>
                  </li>}
                </div>


              </ul>
            </div>
            {vehicle ? <div className="card-body">

              {current_page === pages.basic_info && <BasicInfo vehicle={vehicle} history={history} match={match}/>}
              {loadingPositions && <CircularProgress/>}
              {current_page === pages.photos && <VehiclePhotos
                onImagePositionChange={this.handlePositionChange} images={images} match={match}/>}
              {/* {current_page === pages.yard_expenses &&
                                        <YardExpenses vehicle={vehicle} match={match}/>}*/}
              {/*{current_page === pages.documents &&
                                        <Documents vehicle={vehicle} match={match}/>}*/}
              {current_page === pages.sale && <Sales/>}
              {current_page === pages.payments && <Payments vehicle={vehicle} history={history} match={match}/>}
            </div> : <div className="text-center my-5">
              <CircularProgress/>
            </div>}
          </div>
        </div>

      </div>);
  }
}


export default VehicleDetails;