import {makeRequest} from "../helpers/network_utils";
import {GET_REQUEST} from "../values/globals";
import {parseErrorResponse, showAlert} from "../helpers/helper_functions";

export const onMount = (self, initUrl, key) => {
    self.setState({
        loading: true
    })
    getData(initUrl, self, key);
}

const getData = (url, self, key) => {

    makeRequest(GET_REQUEST, url, {}, response => {
        const res = response.data;
        if (res) {
            let myObj = {};
            myObj[key] = res;
            self.setState(myObj);
        }

    }, error => {
        showAlert('error', 'Error', parseErrorResponse(error))
    }, () => {
        self.setState({
            loading: false,
        })
    })
}