import React, {Component} from 'react';
import {makeRequest} from "../../../helpers/network_utils";
import {POST_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import Swal from "sweetalert2";
import {handleChange} from "../../../helpers/helper_functions";

const $ = window.$;

class ModelsAdd extends Component {

    state = {
        name: '',
        loading: false,
        errors: {}
    }
    handleSubmit = () => {
        this.setState({
            loading: true
        })
        const {name, make} = this.state;
        makeRequest(POST_REQUEST, endpoints.models, {name: name, make: make}, () => {
            Swal.fire(
                'Success!',
                'Model was saved successfully!',
                'success'
            ).then(() => {
                this.props.getModels();
                $("#addModelModal").modal('toggle');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
            });
        }, (error) => {
            this.setState({
                errors: error.response.data
            })
        }, () => {
            this.setState({loading: false})
        })
    }

    render() {
        const {errors, loading} = this.state;
        const {makes} = this.props;

        return (
            <div className="modal fade" id="addModelModal" role="dialog" aria-labelledby="exampleModalCenterTitle"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Add Model</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12">
                                <form>

                                    <div className="form-group">
                                        <label>Make</label>
                                        {
                                            makes ?
                                                <select onChange={(e) => handleChange(e, this)}
                                                        className="form-control" name="make">
                                                    <option>Select a make</option>
                                                    {makes.map((make) => <option key={make.id}
                                                                                 value={make.id}>{make.name}</option>)}
                                                </select> :
                                                <select className="form-control">
                                                    <option>Loading...</option>
                                                </select>
                                        }
                                        {errors.make && <p className="small text-danger">{errors.make[0]}</p>}

                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Model</label>
                                        <input onChange={(e) => handleChange(e, this)} type="text"
                                               className="form-control" name="name"/>
                                        {errors.name && <p className="small text-danger">{errors.name[0]}</p>}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button onClick={this.handleSubmit} type="button" className="btn btn-primary"
                                    disabled={loading}>{loading ? "Loading..." : "Save"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default ModelsAdd;