import urls from "./urls";

export const routes = [
    urls.index,
    urls.admin_settings,
    urls.settings,
    urls.logout,
    urls.login,
    urls.vehicles_list,
    urls.vehicle_add,
    urls.vehicle_images_add,
    urls.vehicle_edit,
    urls.vehicle_details,
    urls.sales_requests,
    urls.import_requests,
    urls.trade_in_requests,
    urls.vehicle_requests_details,
    urls.enquiries,
    urls.enquiry_details,
    urls.messages,
    urls.message_details,
    urls.sold_vehicles,
    /* urls.expenses,
     urls.expense_details,

     urls.client_details,
     urls.clients,
     urls.sales_person_details,
     urls.sales_people,
     urls.sold_vehicles,*/
];