import React, {Component} from 'react';
import endpoints from "../../routes/endpoints";
import {onMount} from "../../providers/loader_plain";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../components/Table";
import {vehicleRequestColumns} from "../../providers/vehicle_request/data";
import {search} from "../../providers/search/loader";

class SalesRequests extends Component {
    state = {
        sales_requests: []
    }

    componentDidMount() {
        this.getSalesRequests();
    }

    getSalesRequests = () => {
        onMount(this, `${endpoints.sales_requests}?for_sale=true`,"sales_requests");
    }

    searchAction = (value) => {
        search(`${endpoints.sales_requests}?for_sale=true`, value, this,"sales_requests");
    }

    render() {
        let {loading, sales_requests,loadingSearch} = this.state;

        return (
            loading ?
                <div className="text-center my-5 py-5">
                    <CircularProgress/>
                </div> :
                <div className="card">
                    <div className="card-body">
                        <Table
                            caption={"All Sales Requests"}
                            hideAction={true}
                            actionText={"Add sales_request"}
                            columns={vehicleRequestColumns}
                            data={sales_requests}
                            loadingSearch={loadingSearch}
                            searchAction={this.searchAction}
                        />
                    </div>
                </div>
        );
    }
}

export default SalesRequests;