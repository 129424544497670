import React from 'react';

import ScrollToTop from "./components/main/scroll_to_top";
import Main from "./components/main";
import {BrowserRouter as Router} from "react-router-dom";
import store from "./redux/store";
import {Provider} from "react-redux";
import './assets/scss/main.scss';

function App() {

    return (
        <Provider store={store}>
            <Router>
                <ScrollToTop>
                    <Main/>
                </ScrollToTop>
            </Router>
        </Provider>
    );
}

export default App;
