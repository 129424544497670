import React, {Component} from 'react';
import {getUserObject, setUserObject} from "../../../helpers/login";
import EditProfile from "./edit";

const $ = window.$;

class Profile extends Component {

    getUser = () => {
        setUserObject(null, () => this.setState({}));
    }

    handleEditProfile = e => {
        e.preventDefault();
        $('#editProfileModal').modal('show');
    }

    render() {
        const user = getUserObject();
        return (
            <div>
                <EditProfile getUser={this.getUser}/>
                <div className="row">
                    <div className="col-xl-4 col-md-6 profile-info-position">
                        <div className="card card-shadow mb-4 h-100">
                            <div className="card-body">
                                <div className="text-center">
                                    <div className="mt-4 mb-3">
                                        {/*<img className="rounded-circle" src="/assets/img/avatar/avatar-large3.jpg"
                                             width="85" alt=""/>*/}
                                    </div>
                                    <h5 className="text-uppercase mb-0">{user.first_name} {user.last_name}</h5>
                                    <p className="text-muted mb-0">Administrator</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-8 col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="media text-muted mb-3">
                                    <p className="media-body pb-3 mb-0">
                                        <span className="d-block text-secondary"><strong className="">First
                                            Name</strong></span>
                                        {user.first_name}
                                    </p>
                                </div>
                                <div className="media text-muted mb-3">
                                    <p className="media-body pb-3 mb-0">
                                        <span className="d-block text-secondary"><strong className="">Last
                                            Name</strong></span>
                                        {user.last_name}
                                    </p>
                                </div>
                                <div className="media text-muted mb-3">
                                    <p className="media-body pb-3 mb-0">
                                        <span className="d-block text-secondary"><strong
                                            className="">Email</strong></span>
                                        {user.email}
                                    </p>
                                </div>
                               {/* <div className="media text-muted mb-3">
                                    <p className="media-body pb-3 mb-0">
                                        <span className="d-block text-secondary"><strong
                                            className="">Phone</strong></span>
                                        {user.phone_number ?? "-"}
                                    </p>
                                </div>*/}
                                <button onClick={this.handleEditProfile}
                                        className="btn btn-info w-100 btn-sm mt-3">Edit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                  {/*<button onClick={() => populateMakes()} className="btn btn-sm btn-info mt-3 mr-2">
                    Populate Makes
                </button>
                <button onClick={() => populateModels()} className="btn btn-sm btn-info mt-3">
                    Populate Models
                </button>*/}
            </div>
        );
    }
}

export default Profile;