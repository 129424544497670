import React, {Component} from 'react';
import AddVehicle from "./add";
import AddImages from "./add_images";
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST, PATCH_REQUEST, POST_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import Swal from "sweetalert2";
import {handleChangeData} from "../../../helpers/helper_functions";
import {Link} from "react-router-dom";
import {getUserObject} from "../../../helpers/login";

const pages = {
    add_vehicle: "/vehicles/add",
    edit_vehicle: "/vehicles/edit",
    add_images: "/vehicles/images/add"
}

class VehicleMain extends Component {

    state = {
        loading: false,
        vehicle: null,
        errors: {},
        data: {}
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    componentDidMount() {
        this._ismounted = true;
        const {location} = this.props;
        const current_page = location.pathname;
        if (current_page.startsWith(pages.edit_vehicle))
            this.getVehicle();
        else {
            //Auto fill logged in user details
            const user = getUserObject();
            const data = {
                sales_person_email: user.email
            }
            this.setState({
                data: data
            })
        }

    }

    getVehicle = () => {
        const {id} = this.props.match.params;
        makeRequest(GET_REQUEST, `${endpoints.vehicles}${id}`, {}, response => {
            let vehicle = response.data;

            let features = vehicle.features;
            vehicle.features = features.map(feature => feature.id);
            if (this._ismounted)
                this.setState({
                    data: vehicle,
                    vehicle: vehicle
                })
        })
    }
    handleSubmit = () => {
        let {data} = this.state;
        if (data?.supplier?.id)
            data.supplier = data?.supplier?.id;
        if (data?.model?.id)
            data.model = data?.model?.id;
        const {
            history,
            location,
            match
        } = this.props;
        const {id} = match.params;
        const current_page = location.pathname;
        this.setState({loading: true});
        const method = current_page === pages.add_vehicle ? POST_REQUEST : PATCH_REQUEST;
        const url = current_page === pages.add_vehicle ? endpoints.vehicles : `${endpoints.vehicles}${id}/`
        makeRequest(method, url, data, response => {
            Swal.fire(
                'Success!',
                `Vehicle was ${current_page === pages.add_vehicle ? "saved" : "edited"} successfully!`,
                'success'
            ).then(() => {
                const vehicle = response.data;
                history.push(`${pages.add_images}/${vehicle.slug}`);
                this.setState({
                    vehicle: response.data
                })
                this.getVehicle();
            });
        }, error => {
            this.setState({errors: error.response.data})
        }, () => {
            this.setState({loading: false})
        })
    }

    handleChange = (e, me, number) => {
        handleChangeData(e, this, number);
    }

    render() {
        const {
            loading,
            vehicle,
            errors,
            data
        } = this.state;
        const {
            history,
            location,
            match
        } = this.props;
        const {id} = match.params;
        const current_page = location.pathname;
        return (
            <div className="col-12">
                <div className="row mb-3 justify-content-end">
                    <button
                        className={`btn ${current_page === pages.add_vehicle || current_page.startsWith(pages.edit_vehicle) ? "btn-info" : "btn-outline-info"} btn-pill btn mr-3`}
                        onClick={() => {
                            if (id)
                                history.push(`${pages.edit_vehicle}/${id}`)
                            else
                                history.push(`${pages.add_vehicle}`)
                        }}>
                        1. Enter Details
                    </button>

                    <button
                        className={`btn ${current_page.startsWith(pages.add_images) ? "btn-info" : "btn-outline-info"} btn-pill btn`}
                        onClick={() => {
                            if (vehicle) {
                                history.push(`${pages.add_images}/${vehicle.slug}`)
                            }
                        }}>
                        2. Add Images
                    </button>
                </div>
                {(current_page === pages.add_vehicle || current_page.startsWith(pages.edit_vehicle)) &&
                <AddVehicle handleChange={this.handleChange} errors={errors} data={data}
                            editing={current_page !== pages.add_vehicle}/>}
                {current_page.startsWith(pages.add_images) &&
                <AddImages history={history} location={location} match={match}/>}
                <div className="col-12 text-right">
                    <button disabled={loading} onClick={() => {
                        history.goBack();
                    }} type="button" className="btn btn-danger text-center mr-3 my-2"><i
                        className="icon-close mr-2"/> Cancel
                    </button>
                    {
                        current_page === pages.add_vehicle || current_page.startsWith(pages.edit_vehicle) ?
                            <button onClick={this.handleSubmit} type="button" disabled={loading}
                                    className="btn btn-primary text-center my-2">{loading ? "Loading..." : "Next"}
                                <i
                                    className="icon-arrow-right ml-2"/></button> :
                            <button disabled={loading} onClick={() => {
                                history.goBack();
                            }} type="button" className="btn btn-primary text-center my-2"><i
                                className="icon-arrow-left mr-2"/> {loading ? "Loading..." : "Previous"} </button>
                    }
                    {
                        current_page.startsWith(pages.add_images) ?
                            <Link className="btn btn-success ml-3" to={`/vehicles/${id}`}>Complete</Link> : null
                    }
                </div>
            </div>
        );
    }
}

export default VehicleMain;