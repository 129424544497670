import {FETCH_VEHICLES, LOADING_VEHICLES} from "./actions";

const vehiclesState = {
    vehicles: null,
};

const reducer = (state = vehiclesState, action) => {
    if (action.type === FETCH_VEHICLES) {
        return {...state, vehicles: action.payload, sold_vehicles: action.payload, loading: false};
    }
    if (action.type === LOADING_VEHICLES) {
        return {...state, loading: true};
    }
    return state;
};

export default reducer;