import React, {Component} from 'react';
import {logout} from "../../helpers/login";

class Logout extends Component {

    componentDidMount() {
        logout();
    }

    render() {
        return (
            <div>
                Logging out..
            </div>
        );
    }
}

export default Logout;