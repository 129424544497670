import React from 'react';
import SingleImage from "./single_photo";
import {DELETE_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import {makeRequest} from "../../../../helpers/network_utils";
import {parseErrorResponse, showAlert} from "../../../../helpers/helper_functions";

const $ = window.$;

class VehiclePhotos extends React.Component {
  state = {
    image_index: 0,
    loading: false
  }

  handleSingleImageClick = (e, index) => {
    e.preventDefault();
    this.setState({
      image_index: index
    })
    $('#imageModal').modal('show');
  }

  handleImageNext = () => {
    const {images} = this.props;
    let {image_index} = this.state;
    image_index++;
    if (image_index < images.length)
      this.setState({
        image_index: image_index
      })
  }

  handleImagePrevious = () => {
    let {image_index} = this.state;
    image_index--;
    if (image_index > -1)
      this.setState({
        image_index: image_index
      })
  }

  handleDelete = (image) => {
    this.setState({
      loading: true
    })
    makeRequest(DELETE_REQUEST, `${endpoints.vehicle_images}${image.id}`, {}, () => {

    }, (error) => {
      showAlert('error', "Error", parseErrorResponse(error))
    }, () => {
      this.setState({
        loading: false
      })
    })
  }

  render() {
    const {images, onImagePositionChange} = this.props;
    const {image_index} = this.state;

    return (
      <div className="col mx-auto">
        <div className="row mx-auto">
          <ImageModal handleImagePrevious={this.handleImagePrevious} handleImageNext={this.handleImageNext}
                      image={image_index < images.length ? images[image_index] : null}/>
          {images.map((image, index) =>
            <SingleImage
              onImagePositionChange={onImagePositionChange}
              handleClick={(e, index) => this.handleSingleImageClick(e, index)} index={index}
              key={image.id} image={image} images={images}/>)}
        </div>
      </div>
    );
  }
}

function ImageModal(props) {
  const {image, handleImagePrevious, handleImageNext} = props;
  return (
    <div className="modal fade" id="imageModal" tabIndex="-1" role="dialog"
         aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-body p-0">
            <div className="d-flex flex-column m-0 p-0">
              <div className="d-flex align-items-center justify-content-center">
                <i onClick={handleImagePrevious} style={{fontSize: "1.5rem", cursor: "pointer"}}
                   className="icon-arrow-left text-white mr-3"/>
                {
                  image && <img style={{cursor: "zoom-in", height: "80vh"}}
                                className="imi img-responsive"
                                src={image.image}
                                srcSet={image.image} alt="car"/>
                }
                <i onClick={handleImageNext} style={{fontSize: "1.5rem", cursor: "pointer"}}
                   className="icon-arrow-right text-white ml-3"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VehiclePhotos;