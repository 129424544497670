import Swal from "sweetalert2";
/*import {makeRequest} from "./network_utils";
import {GET_REQUEST, POST_REQUEST} from "../values/globals";
import endpoints from "../routes/endpoints";

import makes from './makes.csv';
import models from './models.csv';

const csv = require('jquery-csv');*/

export const parseErrorResponse = function (error) {
    let message;
    if (error.response !== null && error.response !== undefined) {
        console.log(error.response.data);
        if (error.response.data.detail) {
            message = error.response.data.detail;
        } else {
            let response = error.response.data;
            if (typeof response === "string") {
                try {
                    message = getStringFromObject(JSON.parse(response));
                } catch (e) {
                    message = "An error occurred. Please try again or contact support";
                }
            } else if (typeof response === 'object') {
                message = getStringFromObject(response);
            } else {
                message = response;
            }
        }


        if (message.length === 0) {
            switch (error.response.status) {
                case 401:
                    message = "Unauthorised. Please log in";
                    break;
                case 404:
                    message = "The requested resource could not be found";
                    break;
                default:
                    message = "An error occurred. Please try again, or contact support";
                    break;
            }
        }
    } else {
        if (typeof error === "string") {
            message = error;
        } else {
            message = "An error occurred. Please try again, or contact support";
        }

    }

    return message;
};

const getStringFromObject = function (obj) {
    let message = "";
    const map = new Map(Object.entries(obj));
    for (const [key, value] of map.entries()) {
        message = `${message} ${key}: ${value}`;
    }
    return message;
};

export const showAlert = (type, title, body) => {
    Swal.fire({
        title: title,
        timer: 5000,
        icon: type,
        text: body,
        timerProgressBar: true,
    }).then();
}

export const handleChange = (e, self) => {
    const name = e.target.name;
    const value = e.target.value;
    self.setState({[name]: value});
}

export const handleChangeData = (e, self, number) => {
    const name = e.target.name;
    let value = e.target.value;
    if (number) {
        value = e.target.value ? parseInt(e.target.value.replace(/,/g, "")) : 0;
        if (isNaN(value))
            return;
    }

    let data = {...self.state.data};
    data[name] = value;
    self.setState({data: data});
}

export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const toTitleCase = (str) => {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

export const replaceHyphensString = (str, delimiter = "-") => {
    return str.replace(/\//g, delimiter);
}

export const getUrl = (link) => {
    if (link.startsWith("http")) {
        link = link.substring(link.lastIndexOf("api") + 7);
    }
    return link;
}

export const throttle = // Returns a function, that, when invoked, will only be triggered at most once
// during a given window of time. Normally, the throttled function will run
// as much as it can, without ever going more than once per `wait` duration;
// but if you'd like to disable the execution on the leading edge, pass
// `{leading: false}`. To disable execution on the trailing edge, ditto.
    function throttle(func, wait, options) {
        let context, args, result;
        let timeout = null;
        let previous = 0;
        if (!options) options = {};
        let later = function () {
            previous = options.leading === false ? 0 : Date.now();
            timeout = null;
            result = func.apply(context, args);
            if (!timeout) context = args = null;
        };
        return function () {
            let now = Date.now();
            if (!previous && options.leading === false) previous = now;
            let remaining = wait - (now - previous);
            context = this;
            args = arguments;
            if (remaining <= 0 || remaining > wait) {
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }
                previous = now;
                result = func.apply(context, args);
                if (!timeout) context = args = null;
            } else if (!timeout && options.trailing !== false) {
                timeout = setTimeout(later, remaining);
            }
            return result;
        };
    };

export function toDateString(date, delimiter = "/") {
    let curr_date = date.getDate();
    let curr_month = date.getMonth() + 1; //Months are zero based
    let curr_year = date.getFullYear();
    const curr_date_string = `${curr_date < 10 ? "0" : ""}${curr_date}`;
    const curr_month_string = `${curr_month < 10 ? "0" : ""}${curr_month}`;
    return `${curr_date_string}${delimiter}${curr_month_string}${delimiter}${curr_year}`;
}

export function toDateTimeString(date, delimiter = "/") {
    return `${toDateString(new Date(date), delimiter)}, ${new Date(
        date
    ).toLocaleTimeString()}`;
}

/*export const populateMakes = () => {
    let makesData;

    window.$.ajax({
        url: makes,
        async: false,
        success: (csvd) => {
            makesData = csv.toArrays(csvd);
        },
        dataType: "text",
        complete: () => {
            //populate models.
            for (let i = 0; i < makesData[0].length; i++)
                populateMake(i, makesData[0]);
        }
    });
}*/

/*const populateMake = (i, arr) => {
    setTimeout(() => {
        makeRequest(POST_REQUEST, endpoints.makes, {name: arr[i]});
    }, 100);
}*/

/*export const populateModels = () => {
    let modelsData;
    window.$.ajax({
        url: models,
        async: false,
        success: (csvd) => {
            modelsData = csv.toArrays(csvd);
            let count = 0;
            makeRequest(GET_REQUEST, endpoints.makes, {}, response => {
                const makes = response.data;
                let sqlStatement = "insert into models(name,make_id) values";
                for (let i = 0; i < modelsData.length; i++) {
                    const modelRow = modelsData[i];
                    for (let f = 1; f < modelRow.length; f++) {
                        const id = getMakeId(modelRow[0], makes);
                        /!* if (i === 0)
                             populateModel(id, modelRow[f]);*!/


                        if (id) {
                            sqlStatement += `('${modelRow[f]}','${id}')`
                            // console.log(id, modelRow[0], modelRow[f]);
                            // if (i === 0) {
                            //populateModel(id, modelRow[f],sqlStatement);
                            //}
                            if (f === modelRow.length - 1 && i === modelsData.length - 1)
                                sqlStatement += ";\n\r";
                            else
                                sqlStatement += ",\n\r";

                            count++;
                        }

                    }
                }
                localStorage.setItem("SQL_QUERY", sqlStatement);
                localStorage.setItem("SQL_QUERY_COUNT", `${count}`);

            })
        },
        dataType: "text",
        complete: () => {
            // console.log(modelsData);
        }
    });
}*/

/*const getMakeId = (id, makes) => {
    for (let i = 0; i < makes.length; i++) {
        const make = makes[i];
        if (make.name === id)
            return make.id;
    }
    return null;
}*/


/*const populateModel = (id, name) => {
    setTimeout(() => {
        makeRequest(POST_REQUEST, endpoints.models, {name: name, make: id}, null, () => {
            const failedModels = JSON.parse(localStorage.getItem("failed_models") || "[]");
            failedModels.push({name: name, make: id});
            localStorage.setItem("failed_models", JSON.stringify(failedModels));
        });
    }, 1);
}*/
