import React, {Component} from 'react';
import {POST_REQUEST} from "../../values/globals";
import Swal from "sweetalert2";
import {makeRequest, scheduleTokenRefreshes} from "../../helpers/network_utils";
import {saveRefreshToken, saveToken, setUserObject} from "../../helpers/login";
import {handleChange, parseErrorResponse} from "../../helpers/helper_functions";
import logo from '../../assets/img/logo.png';

const $ = window.$;

class Login extends Component {
    state = {
        error: null,
        response: null,
        email: '',
        password: '',
        loading: false,
        showPassword: false
    };

    componentDidMount() {
        $(".input-main-border").find(":input").focus(function () {
            $(this).parent().css("border-color", "blue");
        }).blur(function () {
            $(this).parent().css("border-color", "var(--primary-color)");
        });
    }

    handleSubmit = event => {
        event.preventDefault();

        this.setState({loading: true});

        let self = this;

        makeRequest(POST_REQUEST, "/auth/jwt/create/", {
            email: this.state.email?.toLowerCase(),
            password: this.state.password?.toLowerCase()
        }, function (response) {

            let {access, refresh} = response.data;
            saveToken(access);
            saveRefreshToken(refresh);

            self.setState({
                response: null,
                error: null
            });

            //scheduleTokenRefreshes();
            setUserObject(function () {

            }, function () {
                window.location.href = "/";
                scheduleTokenRefreshes();
                self.setState({
                    loading: false
                });
            });
        }, function (error) {
            self.setState({
                error: error,
                response: null,
                loading: false
            });
        },)
    };

    resetPasswordDialog = () => {
        Swal.fire({
            title: 'Enter your email',
            input: 'email',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Reset Password',
            showLoaderOnConfirm: true,
            preConfirm: (email) => {
                makeRequest(POST_REQUEST, "auth/users/reset", {email: email}, () => {
                    Swal.fire(
                        'Success!',
                        'A password reset link has been sent to your email.',
                        'success'
                    ).then(() => {
                        return true;
                    });
                }, (error) => {
                    Swal.showValidationMessage(
                        `Request failed: ${parseErrorResponse(error)}`
                    );
                    return true;
                })
                return false;
            },
            allowOutsideClick: () => !Swal.loading()
        }).then(() => {
            Swal.showValidationMessage(
                `Request failed: `
            );
        });
    }

    render() {
        let message;
        const {error, response, loading, showPassword} = this.state;
        if (error) {
            message = "The credentials provided are invalid.";
            //message = parseErrorResponse(error);
        } else if (response != null) {
            message = "";
            Object.values(response.data).forEach(function (item) {
                message += item + " ";
            });
        }

        return (
            <div className="signin-up-bg w-100 h-100">
                <div className="leftHalf" style={{
                    backgroundImage: "url('assets/img/logo.png')",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat"
                }}>
                    {/* <div className="login-promo-txt">
                        <h2>Gigi Motors</h2>
                        <p>Admin Panel</p>
                    </div>*/}
                </div>

                <div className="rightHalf">
                    <div className="position-relative">
                        <div className="login-form">
                            <h2 className="text-center mb-4">
                                <img style={{width: "30vw", height: "20vh", objectFit: "contain"}} src={logo}
                                     srcSet={logo} alt="CodeLab"/>
                            </h2>
                            <h4 className="text-uppercase- text-purple text-center mb-5">Login to Admin Dashboard</h4>
                            <div style={message ? {display: 'block'} : {display: 'none'}}
                                 className="alert alert-danger alert-dismissible fade show" role="alert">
                                <strong>Information: </strong> {message}
                                <button type="button" className="close" data-hide="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group text-left">
                                    <input onChange={(e) => handleChange(e, this)} type="email" className="form-control"
                                           name="email"
                                           placeholder="Enter email"/>
                                </div>
                                <div className="form-group mb-4">
                                    <div className="input-group mb-3 input-main-border">
                                        <input onChange={(e) => handleChange(e, this)}
                                               type={showPassword ? "text" : "password"} className="form-control"
                                               name="password"
                                               placeholder="Enter Password"/>
                                        <div className="input-group-append input-main-border-icon">
                                            <span onClick={() => {
                                                this.setState({
                                                    showPassword: !showPassword
                                                })
                                            }} style={{cursor: "pointer"}}
                                                  className="input-group-text bg-white material-icons">
                                                {showPassword ? "visibility" : "visibility_off"}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group clearfix">
                                    <a onClick={(e) => {
                                        e.preventDefault();

                                        //Show popup that will allow the user to input email and send verification request.
                                        this.resetPasswordDialog();
                                    }} href="#forgot" className="float-left forgot-link my-2">Forgot Password ?</a>
                                    <button type="submit" disabled={loading}
                                            className="btn btn-purple btn-pill float-right">{loading ? "LOADING.." : "LOGIN"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;