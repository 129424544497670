import React, {Component} from 'react';
import {makeRequest} from "../../../../helpers/network_utils";
import {POST_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import Swal from "sweetalert2";
import {handleChangeData, numberWithCommas} from "../../../../helpers/helper_functions";
import Select from "react-select";
import {getTradeInVehicles} from "../../../../redux/vehicles/actions";
import {connect} from "react-redux";

const $ = window.$;

const mapStateToProps = state => state.vehiclesState;

const mapDispatchToProps = dispatch => ({
    getTradeInVehicles: () => dispatch(getTradeInVehicles()),
})

class AddPayment extends Component {

    state = {
        loading: false,
        errors: {},
        data: {}
    }

    componentDidMount() {
        if (this.props.vehicles == null)
            this.props.getTradeInVehicles();
    }

    handleSubmit = () => {
        let {data} = this.state;
        let errors = {};
        if (!data.payment_type) {
            this.setState({
                errors: {...errors, payment_type: ["The payment type field is required"]}
            })
            return;
        }
        if (data.payment_type === "Trade in") {
            if (!data.trade_in_vehicle) {
                this.setState({
                    errors: {...errors, trade_in_vehicle: ["The trade in vehicle field is required"]}
                })
                return;
            }
        }

        if (data.payment_type === "Cash") {
            if (!data.amount) {
                this.setState({
                    errors: {...errors, amount: ["The amount field is required"]}
                })
                return;
            }
        }

        this.setState({
            loading: true
        })

        const {vehicle} = this.props;
        data.vehicle_sale = vehicle.sale.id;
        makeRequest(POST_REQUEST, `${endpoints.payments}${vehicle.id}/`, data, () => {
            Swal.fire(
                'Success!',
                'Payment was saved successfully!',
                'success'
            ).then(() => {
                this.props.getPayments();
                $("#addPaymentModal").modal('toggle');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
            });
        }, (error) => {
            this.setState({
                errors: error.response.data
            })
        }, () => {
            this.setState({loading: false})
        })
    }

    handleChangeVehicle = value => {
        this.setState({
            data: {...this.state.data, trade_in_vehicle: value.value}
        })
    }

    render() {
        const {errors, loading, data} = this.state;
        const {history, vehicles, vehicle} = this.props;
        let vehicleOptions = [];
        if (vehicles)
            for (let i = 0; i < vehicles.length; i++) {
                let theVehicle = vehicles[i];
                if (theVehicle.id !== vehicle.id) {
                    vehicleOptions.push({
                        value: theVehicle?.id,
                        label: `${theVehicle?.number_plate || theVehicle?.chassis_number} - 
                        ${theVehicle?.model?.make?.name}  ${theVehicle?.model?.name}`
                    })
                }
            }

        return (
            <div className="modal fade" id="addPaymentModal" role="dialog" aria-labelledby="exampleModalCenterTitle"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Add Payment</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12">
                                <form>
                                    <div className="form-group">
                                        <label>Payment Type</label>
                                        <select onChange={(e) => handleChangeData(e, this)}
                                                className="form-control"
                                                name="payment_type">
                                            <option value="">Select Payment Type</option>
                                            <option value="Cash">Cash</option>
                                            <option value="Trade in">Trade In</option>
                                        </select>
                                        {errors.payment_type &&
                                        <p className="small text-danger">{errors.payment_type[0]}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label>Title</label>
                                        <input onChange={(e) => handleChangeData(e, this)} type="text"
                                               className="form-control" name="title"/>
                                        {errors.title && <p className="small text-danger">{errors.title[0]}</p>}
                                    </div>
                                    {
                                        data.payment_type === "Trade in" &&
                                        <div className="form-group">
                                            <div
                                                className="row mx-auto justify-content-between my-2 align-items-center">
                                                <label className="m-0">Vehicle</label>
                                                <button onClick={e => {
                                                    e.preventDefault();
                                                    $("#addPaymentModal").modal('toggle');
                                                    $('body').removeClass('modal-open');
                                                    $('.modal-backdrop').remove();
                                                    history.push("/vehicles/add")
                                                }} className="btn btn-sm btn-success">
                                                    Add Vehicle
                                                </button>
                                            </div>
                                            <Select options={vehicleOptions} name="trade_in_vehicle"
                                                    onChange={this.handleChangeVehicle}/>
                                            {errors.trade_in_vehicle &&
                                            <p className="small text-danger">{errors.trade_in_vehicle[0]}</p>}
                                        </div>
                                    }

                                    {/*<div className="form-group">
                                        <label>Description</label>
                                        <input onChange={(e) => handleChangeData(e, this)} type="text"
                                               className="form-control" name="description"/>
                                        {errors.description &&
                                        <p className="small text-danger">{errors.description[0]}</p>}
                                    </div>*/}
                                    {
                                        data.payment_type === "Cash" &&
                                        <div className="form-group">
                                            <label>Amount</label>
                                            <input
                                                onChange={(e) => {
                                                    let value = e.target.value ? parseInt(e.target.value.replace(/,/g, "")) : 0;
                                                    if (!isNaN(value)) {
                                                        e = {
                                                            target: {
                                                                value: value,
                                                                name: "amount"
                                                            }
                                                        }
                                                    }
                                                    handleChangeData(e, this)
                                                }}
                                                value={numberWithCommas(`${data?.amount ?? ""}`.replace(/,/g, "") ?? "")}
                                                type="text"
                                                className="form-control" name="amount"/>
                                            {errors.amount && <p className="small text-danger">{errors.amount[0]}</p>}
                                        </div>
                                    }
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button onClick={this.handleSubmit} type="button" className="btn btn-primary"
                                    disabled={loading}>{loading ? "Loading..." : "Save"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPayment);