import React from "react";
import {numberWithCommas, parseErrorResponse, showAlert} from "../../../helpers/helper_functions";
import Swal from "sweetalert2";
import {makeRequest} from "../../../helpers/network_utils";
import {DELETE_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";

class BasicInfo extends React.Component {

    state = {}

    handleDelete = () => {
        const {id} = this.props.match.params;
        //const {history} = this.props;
        Swal.fire({
                title: 'Information!',
                text: 'Are you sure you want delete this?',
                icon: 'info',
                showCancelButton: true,
                cancelButtonText: 'Cancel'
            }
        ).then(result => {
            if (result.isConfirmed) {
                this.setState({
                    loadingDelete: true
                })
                makeRequest(DELETE_REQUEST, `${endpoints.vehicles}${id}`, {}, () => {
                    Swal.fire(
                        'Success!',
                        'Vehicle deleted successfully!',
                        'success',
                    ).then(() => {
                        window.location.href = "/vehicles";
                    });
                }, error => {
                    showAlert('error', 'Error', parseErrorResponse(error))
                }, () => {
                    this.setState({
                        loadingDelete: false
                    })
                })
            }

        });
    }

    render() {
        const {vehicle, history} = this.props;
        const {loadingDelete} = this.state;
        return (
            <div className="tab-pane">
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Property</th>
                        <th scope="col">Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th scope="row">
                            Make
                        </th>
                        <td>
                            {vehicle?.model?.make?.name}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Model
                        </th>
                        <td>
                            {vehicle?.model?.name}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Chassis Number
                        </th>
                        <td>
                            {vehicle?.chassis_number}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Year
                        </th>
                        <td>
                            {vehicle?.year}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Color
                        </th>
                        <td>
                            {vehicle?.color}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Engine Size
                        </th>
                        <td>
                            {vehicle?.engine_size}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Fuel Type
                        </th>
                        <td>
                            {vehicle?.fuel_type}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Drive Type
                        </th>
                        <td>
                            {vehicle?.drive_type}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Body Type
                        </th>
                        <td>
                            {vehicle?.body_type}
                        </td>
                    </tr>
                    {/*<tr>
                    <th scope="row">
                        Supplier
                    </th>
                    <td>
                        {vehicle?.supplier?.name || "-"}
                    </td>
                </tr>*/}
                    <tr>
                        <th scope="row">
                            Buying Price
                        </th>
                        <td>
                            {numberWithCommas(vehicle?.buying_price || "0")}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Added By
                        </th>
                        <td>
                            {vehicle?.added_by}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Features
                        </th>
                        <td>
                            {vehicle?.features?.map(feature => <div key={feature.id}>{feature.name}</div>)}
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div className="d-flex flex-sm-row flex-column flex-wrap justify-content-between">
                    <div>
                        {!vehicle?.bought && <button onClick={() => {
                            history.push(`/vehicles/edit/${vehicle.slug}`)
                        }} type="button" className="btn btn-block btn-info btn-sm mr-3">Edit
                            Info
                        </button>}
                    </div>
                    <div>
                        <button disabled={loadingDelete} onClick={this.handleDelete}
                                className="btn btn-block btn-danger btn-sm flex-grow-0">
                            {loadingDelete ? "Loading.." : "Delete"}
                        </button>
                    </div>


                </div>
            </div>

        );
    }
}

export default BasicInfo;