import React from "react";
import {Link} from "react-router-dom";
import {parseErrorResponse, showAlert} from "../../../helpers/helper_functions";
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";

class SingleVehicle extends React.Component {
    state = {
        images: [],
        loading: false
    }

    componentDidMount() {
        this.getImages();
        this._mounted = true;
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    getImages = () => {
        const {vehicle} = this.props;
        this.setState({loading: true})
        makeRequest(GET_REQUEST, `${endpoints.vehicle_images}?vehicle__slug=${vehicle.slug}`, {}, response => {
            this.setState({
                images: response.data
            })
        }, error => {
            showAlert('error', 'Error', parseErrorResponse(error))
        }, () => {
            this.setState({
                loading: false
            })
        })
    }

    render() {
        const {vehicle} = this.props;
        const {loading, images} = this.state;
        return (
            <div className="col-md-4">
                <div className="card card-shadow mb-4 bg-white text-black">
                    <div className="card-body">
                        <div className="row d-flex align-items-center">
                            <div className="col-12 text-center">
                                {
                                    loading ?
                                        <div style={{height: "10rem"}} className="loader-circle"/>
                                        :
                                        images.length > 0 ?
                                            <img className="imi d-inline-block mb-3 mb-lg-0 img-responsive"
                                                 src={images[0].image}
                                                 srcSet={images[0].image} alt="car"/> :
                                            <img className="imi d-inline-block mb-3 mb-lg-0 img-responsive"
                                                 src={"/assets/img/404.png"}
                                                 srcSet={"/assets/img/404.png"} alt="car"/>
                                }
                            </div>
                            <div className="col-12">
                                <h4 className="mt-2 weight600 mb-0">{vehicle?.model?.make?.name} {vehicle?.model?.name}</h4>
                                <div className="size mt-2">
                                    {/*{
                                        vehicle?.bought ?
                                            `KES ${numberWithCommas(vehicle.selling_price ?? "0")}` :
                                            `KES ${numberWithCommas(vehicle.buying_price ?? "0")}`
                                    }*/}
                                    <Link to={`/vehicles/${vehicle.slug}`}
                                          className="btn btn-primary btn-pill btn-sm float-right">View</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SingleVehicle;