import React, {Component} from 'react';
import {connect} from "react-redux";
import {getMakes} from "../../../redux/makes/actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../../components/Table";
import ModelsAdd from "./add";
import {getModels} from "../../../redux/models/actions";

const $ = window.$;

const mapStateToProps = state => ({
    makes: state.makesState.makes,
    models: state.modelsState.models,
    loading: state.modelsState.loading
});

const mapDispatchToProps = dispatch => ({
    getMakes: () => dispatch(getMakes()),
    getModels: () => dispatch(getModels()),
});

const columns = [
    {
        dataField: 'id',
        text: 'ID',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'name',
        text: 'Model',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'make',
        text: 'Make',
        formatter: row => `${row?.name ?? "-"}`
    },
]


class Makes extends Component {
    componentDidMount() {
        const {getMakes, makes, models, getModels} = this.props;
        if (makes == null) getMakes();
        if (models == null) getModels();
    }

    handleAddModel = e => {
        e.preventDefault();
        $('#addModelModal').modal('show');
    }

    render() {
        const {loading, makes, models, getModels} = this.props;
        return (loading ?
                <div className="text-center">
                    <CircularProgress/>
                </div> :
                <div>
                    <ModelsAdd makes={makes} getModels={getModels}/>
                    <Table
                        caption={"Models"}
                        actionText={"Add Model"}
                        columns={columns}
                        actionFunc={this.handleAddModel}
                        data={models}
                    />
                </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Makes);