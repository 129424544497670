import {makeRequest} from "../helpers/network_utils";
import {GET_REQUEST} from "../values/globals";
import {getUrl, showAlert, throttle} from "../helpers/helper_functions";

const $ = window.$;

class Loader_redux {
  next;
  //let previous;
  //let count;
  glLoadingType;
  glCompleteType
  glDispatch;
  glIsPaginated;
  glData;

  /**
   *
   * @param {string} initUrl
   * @param {string} loadingType - Redux Action for loading
   * @param {string} completeType - Redux Action for completed loading
   * @param {function} dispatch
   * @param {boolean} [shouldDetectScroll]
   * @param isPaginated
   * @param ignoreInitialLoad
   * @returns {function(*): void}
   */
  constructor(initUrl, loadingType, completeType, dispatch, shouldDetectScroll = false, isPaginated = true, ignoreInitialLoad = false) {
    // We assume that at any point, only one instance of this function will be called.
    // This function will at most be called by one component at a time.
    console.log(initUrl)
    this.glLoadingType = loadingType;
    this.glCompleteType = completeType;
    this.glIsPaginated = isPaginated;
    this.glDispatch = dispatch;
    if (shouldDetectScroll)
      this.detectScroll();
    if (!ignoreInitialLoad)
      this.getData(initUrl);
  };

  onUnMount = () => {
    //$(window).off('scroll');
  }

  getData = (url, loadingNext = false) => {
    this.glDispatch({type: this.glLoadingType, payload: []})
    makeRequest(
      GET_REQUEST,
      url,
      {},
      response => {
        const res = response.data;

        if (this.glIsPaginated) {

          if (loadingNext) {
            this.glData = [...this.glData, ...res.results];
            this.glDispatch({type: this.glCompleteType, payload: this.glData});
          } else {
            this.glData = res.results;
            this.glDispatch({type: this.glCompleteType, payload: res.results});
          }

          //Attach the next and previous links to the state of the component and attach loader on scroll.
          this.next = res.next;
          //previous = res.previous;
          //count = res.count;
        } else
          this.glDispatch({type: this.glCompleteType, payload: res});


      },
      error => {
        if (error.response) {
          showAlert("error", 'Error', error.response.statusText);
        } else {
          showAlert("error", 'Error', error.message);
        }
        this.glDispatch({type: this.glCompleteType, payload: []})
      },
    )
  }

  detectScroll = () => {
    $(window).scroll(() => {
      if ($(window).scrollTop() >= $(document).height() - $(window).height() - 300) {
        this.loadMoreContent();
      }
    });
  }

  loadMoreContent = throttle(() => {
    if (this.next) {
      this.getData(getUrl(this.next), true);
    }
  }, 1000);

}

export default Loader_redux;
