import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../helpers/helper_functions";

export const FETCH_MODELS = "fetch_models";
export const LOADING_MODELS = "loading_models";

export const getModels = () => {
    return dispatch => {
        dispatch({
            type: LOADING_MODELS
        })
        makeRequest(GET_REQUEST, `${endpoints.models}`, {}, response => {
            const models = response.data;
            return dispatch({
                models: models,
                type: FETCH_MODELS
            })
        }, error => {
            showAlert('error', "Error!", parseErrorResponse(error));
            console.log(error);
        });
    }
}