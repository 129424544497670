import {parseErrorResponse, showAlert, throttle} from "../../helpers/helper_functions";
import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST} from "../../values/globals";

let theSearchFunc;
/**
 *
 * @param {string} endpoint
 *
 * @param {string} value
 * @param {React.Component} self
 * @param {number} [wait]
 * @param {string} [key]
 * @param {boolean} [paginated]
 */
export const search = (endpoint, value, self, key, paginated = false, wait = 500,) => {
    if (theSearchFunc)
        theSearchFunc(endpoint, value, self, key, paginated);
    else {
        theSearchFunc = searchFunc(wait);
        theSearchFunc(endpoint, value, self, key, paginated);
    }
}

const searchFunc = (wait) => throttle((endpoint, value, self, key, paginated) => {
    self.setState({
        loadingSearch: true
    });
    const url = endpoint.includes('?') ? `${endpoint}&search=${value}` : `${endpoint}?search=${value}`;
    makeRequest(GET_REQUEST, url, {}, response => {
        const state = {...self.state};
        let data = response.data;
        if (paginated)
            data = data.results;
        if (key)
            state[key] = data;
        else
            state["results"] = data;
        self.setState(state);
    }, error => {
        showAlert('error', 'Error', parseErrorResponse(error))
    }, () => {
        self.setState({
            loadingSearch: false
        });
    })
}, wait)