import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {getUserObject} from "../../helpers/login";
import {useDispatch, useSelector} from "react-redux";
import {getStatistics} from "../../redux/statistics/actions";

function SideBar() {
  const user = getUserObject();
  const statisticsState = useSelector((state) => state.statisticsState);
  const dispatch = useDispatch();
  useEffect(() => {
    if (statisticsState.statistics == null)
      dispatch(getStatistics());
  }, [dispatch])

  let hasUnreadEnquiries = statisticsState?.statistics?.unchecked_enquiries > 0;
  let hasUnreadMessages = statisticsState?.statistics?.unread_messages > 0;

  return (
    <div className="left-nav-wrap">
      <div className="left-sidebar">
        <nav className="sidebar-menu">
          <ul id="nav-accordion">
            <li>
              <Link to="/">
                <i className="ti-home"/>
                <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to="/vehicles">
                <i className="icon-grid"/>
                <span>Inventory</span>
              </Link>
            </li>
            <li>
              <Link to="/vehicles?for_import=True">
                <i className="icon-grid"/>
                <span>Import Inventory</span>
              </Link>
            </li>
            <li>
              <Link to="/enquiries">
                <i className="icon-people"/>
                <span>Enquiries {hasUnreadEnquiries && <Notification/>} </span>
              </Link>
            </li>
            <li>
              <Link to="/sold-vehicles">
                <i className="material-icons">
                  directions_car
                </i>
                <span>Sold Cars</span>
              </Link>
            </li>
            <li>
              <Link to="/sales-requests">
                <i className="ti-bar-chart-alt"/>
                <span>Sales</span>
              </Link>
            </li>

            <li>
              <Link to="/import-requests">
                <i className="icon-layers"/>
                <span>Import Requests</span>
              </Link>
            </li>

            <li>
              <Link to="/trade-in-requests">
                <i className="icon-docs"/>
                <span>Trade-In Requests</span>
              </Link>
            </li>
            <li>
              <Link to="/messages">
                <i className="icon-speech"/>
                <span>Contact Form {hasUnreadMessages && <Notification/>}</span>
              </Link>
            </li>

            <li>
              <Link to="/settings/profile">
                <i className="icon-settings"/>
                <span>Settings</span>
              </Link>
            </li>
            {
              user?.role === "admin" &&
              <li>
                <Link to="/admin/settings">
                  <i className="ti-user"/>
                  <span>System Users</span>
                </Link>
              </li>
            }
          </ul>
        </nav>
      </div>
    </div>
  )
}

export const Notification = () => (
  <span
    style={{
      width: "10px",
      height: "10px",
      display: "inline-block"
    }}
    className="rounded-circle bg-success"/>
)

export default SideBar;