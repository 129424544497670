import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../helpers/helper_functions";

export const FETCH_SUPPLIERS = "fetch_suppliers";
export const LOADING_SUPPLIERS = "loading_suppliers";

export const getSuppliers = () => {
    return dispatch => {
        dispatch({
            type: LOADING_SUPPLIERS
        })
        makeRequest(GET_REQUEST, `${endpoints.suppliers}`, {}, response => {
            const suppliers = response.data;
            return dispatch({
                suppliers: suppliers,
                type: FETCH_SUPPLIERS
            })
        }, error => {
            showAlert('error', "Error!", parseErrorResponse(error));
            console.log(error);
        });
    }
}