import React, {Component} from 'react';
import endpoints from "../../routes/endpoints";
import {onMount} from "../../providers/loader_plain";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../components/Table";
import {messageColumns} from "../../providers/messages/data";
import {search} from "../../providers/search/loader";

class Messages extends Component {
    state = {
        messages: []
    }

    componentDidMount() {
        this.getMessages();
    }

    getMessages = () => {
        onMount(this, endpoints.messages, "messages");
    }

    searchAction = (value) => {
        search(`${endpoints.messages}`, value, this, "messages");
    }

    render() {
        let {loading, messages, loadingSearch} = this.state;

        return (
            loading ?
                <div className="text-center my-5 py-5">
                    <CircularProgress/>
                </div> :
                <div className="card">
                    <div className="card-body">
                        <Table
                            caption={"All Messages"}
                            hideAction={true}
                            columns={messageColumns}
                            data={messages}
                            loadingSearch={loadingSearch}
                            searchAction={this.searchAction}
                        />
                    </div>
                </div>
        );
    }
}

export default Messages;