const endpoints = {
    makes: "vehicle-makes/",
    features: "vehicle-features/",
    models: "vehicle-models/",
    sales_requests: "vehicle-sales/",
    import_requests: "vehicle-sales/?for_import=true",
    trade_in_requests: "vehicle-sales/?for_trade=true",
    enquiries: "vehicle-enquiries/",
    messages: "messages/",
    sales_people: "salespersons/",
    users: "users/",
    clients: "clients/",
    vehicles: "vehicles/",
    vehicle_sales: "vehicle-sales/",
    suppliers: "suppliers/",
    expenses_stats: "expenses/",
    analytics: "analytics/",
    vehicle_images: "vehicle-images/",
    vehicle_records: "vehicle-records/",
    vehicle_payments: "vehicle-records/",
    vehicle_documents: "vehicle-documents/",
    payments: "vehicle-payments/",
    me: "auth/users/me/"
};

export default endpoints;