import React, {Component} from 'react';
import {makeRequest} from "../../../../helpers/network_utils";
import {GET_REQUEST} from "../../../../values/globals";
import endpoints from "../../../../routes/endpoints";
import {
    numberWithCommas,
    parseErrorResponse,
    replaceHyphensString,
    showAlert
} from "../../../../helpers/helper_functions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../../../components/Table";
import AddPayment from "./add";

const $ = window.$;

const columns = [
    {
        dataField: 'created',
        text: 'Date',
        formatter: date => `${replaceHyphensString(new Date(date).toLocaleString())}`
    },
    {
        dataField: 'payment_type',
        text: 'Payment Type',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'title',
        text: 'Payment Category',
        formatter: title => `${title || "-"}`
    },
    /*{
        dataField: 'description',
        text: 'Description',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },*/
    {
        dataField: 'amount',
        text: 'Amount (KSH)',
        formatter: (amount, row) => {
            let newAmount = row.payment_type === "Trade in" ? row?.trade_in_vehicle?.value :
                amount;
            return `KES ${numberWithCommas(newAmount || "-")}`
        }
    },
]

class Payments extends Component {

    state = {
        payments: [],
        sale: null,
        loading: false
    }

    componentDidMount() {
        this.getSale();
        this.getPayments();
        this._mounted = true;
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    getSale = () => {
        const {vehicle} = this.props;
        this.setState({loading: true});
        makeRequest(GET_REQUEST, `${endpoints.vehicle_sales}${vehicle?.sale?.id}/`,
            {}, response => {
                this.setState({sale: response.data})
            }, error => {
                showAlert('error', 'Error', parseErrorResponse(error))
            }, () => {
                this.setState({loading: false})
            })
    }

    getPayments = () => {
        const {vehicle} = this.props;
        this.setState({loading: true});
        makeRequest(GET_REQUEST, `${endpoints.payments}${vehicle?.id}/`,
            {}, response => {
                this.setState({payments: response.data})
            }, error => {
                showAlert('error', 'Error', parseErrorResponse(error))
            }, () => {
                this.setState({loading: false})
            })
    }

    handleAddPayment = e => {
        e.preventDefault();
        $('#addPaymentModal').modal('show');
    }

    setState(state, callback) {
        if (this._mounted)
            super.setState(state, callback);
    }

    render() {
        const {loading, payments, sale} = this.state;
        const {vehicle, match, history} = this.props;
        return (
            loading ?
                <div className="text-center">
                    <CircularProgress/>
                </div> :
                <div className="tab-pane">
                    <AddPayment match={match} history={history} vehicle={vehicle} getPayments={this.getPayments}/>
                    <div className="row">
                        {/*<div className="col-sm">
                            <h4 className="font-weight-normal">
                                Buying Price: <br/>
                                <b>{numberWithCommas(vehicle?.buying_price || "-")}</b>
                            </h4>
                        </div>*/}
                        <div className="col-sm">
                            <h4 className="font-weight-normal">
                                Selling Price: <br/>
                                <b>{numberWithCommas(sale?.selling_price || "-")}</b>
                            </h4>
                        </div>
                        <div className="col-sm">
                            <h4 className="text-danger font-weight-normal">
                                Balance: <br/>
                                <b>{numberWithCommas(sale?.balance || "-")}</b>
                            </h4>
                        </div>
                    </div>
                    <Table
                        caption={"Payments"}
                        actionText={"Add Payment"}
                        actionFunc={this.handleAddPayment}
                        hideAction={!vehicle?.bought}
                        columns={columns}
                        data={payments}
                    />
                </div>
        );
    }
}

export default Payments;