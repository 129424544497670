import {combineReducers} from "redux";

import makesState from './makes/reducers';
import modelsState from './models/reducers';
import suppliersState from './suppliers/reducers';
import vehiclesState from './vehicles/reducers';
import clientsState from './clients/reducers';
import salesPeopleState from './sales_persons/reducers';
import featuresState from './features/reducers';
import statisticsState from './statistics/reducers';

export default combineReducers({
    makesState,
    modelsState,
    suppliersState,
    vehiclesState,
    clientsState,
    salesPeopleState,
    featuresState,
    statisticsState
});