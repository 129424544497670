import React, {Component} from 'react';
import {onMount} from "../../providers/loader_plain";
import endpoints from "../../routes/endpoints";
import CircularProgress from "@material-ui/core/CircularProgress";
import {numberWithCommas} from "../../helpers/helper_functions";

class RequestDetails extends Component {

    state = {
        request: null
    }

    componentDidMount() {
        this.getTradeInRequests();
    }

    getTradeInRequests = () => {
        const {id} = this.props.match.params;
        onMount(this, `${endpoints.sales_requests}${id}`, "request");
    }

    render() {
        let {loading, request} = this.state;
        return (
            loading ?
                <div className="text-center my-5 py-5">
                    <CircularProgress/>
                </div> :
                request ?
                    <div className="row">
                        <div className="col-4 pr-2">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h5>Client Details</h5>
                                    <hr className="my-1"/>
                                    <SingleRow title={"Name"} value={request.name}/>
                                    <SingleRow title={"Phone Number"} value={request.phone_number}/>
                                    <SingleRow title={"Email"} value={request.email}/>
                                    <SingleRow title={"Area"} value={request.area}/>
                                    <SingleRow title={"Enquiry"} value={"Trade In"}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-8 pl-2">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h5>Vehicle Details</h5>
                                    <hr className="my-1"/>
                                    <SingleRow title={"Registration Number"} value={request.number_plate || "-"}/>
                                    <SingleRow title={"Description"} value={request.description || "-"}/>
                                    <SingleRow title={"Condition"} value={request.condition || "-"}/>
                                    <SingleRow title={"Value"}
                                               value={`KES ${numberWithCommas(request.selling_price || "-")}`}/>
                                    <h5 className="mt-5">Technical Details</h5>
                                    <hr className="my-1"/>
                                    <SingleRow title={"Engine Size"} value={`${request.engine_size} CC` || "-"}/>
                                    <SingleRow title={"Transmission Type"} value={request.transmission_type || "-"}/>
                                    <SingleRow title={"Fuel Type"} value={request.fuel_type || "-"}/>
                                    <SingleRow title={"Body Type"} value={request.body_type || "-"}/>
                                    <SingleRow title={"Drive Type"} value={request.drive_type || "-"}/>
                                    <SingleRow title={"Interior"} value={request.interior || "-"}/>
                                </div>
                            </div>
                        </div>
                    </div> :
                    <div className="text-center py-5 my-5">
                        <p className="font-weight-bold">Could not load request</p>
                    </div>
        );
    }
}

function SingleRow(props) {
    return (
        <div className="flex-column">
            <p className="font-weight-bold m-0"> {props.title} </p>
            <p> {props.value} </p>
        </div>
    )
}

export default RequestDetails;