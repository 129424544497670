import React, {Component} from 'react';
import SingleVehicle from "./vehicle";
import CircularProgress from "@material-ui/core/CircularProgress";
import {connect} from "react-redux";
import {FETCH_VEHICLES, LOADING_VEHICLES} from "../../redux/vehicles/actions";
import endpoints from "../../routes/endpoints";
import {search} from "../../providers/search/loader";
import Loader_redux from "../../providers/loader_redux";

const mapStateToProps = state => (state.vehiclesState);

let loaderRedux;

class Imports extends Component {

  state = {}

  componentDidMount() {
    const {dispatch} = this.props;
    const search = this.props.location.search || ""
    loaderRedux = new Loader_redux(
      `${endpoints.vehicles}?sold=false&${search.replaceAll("?", "")}`, LOADING_VEHICLES,
      FETCH_VEHICLES,
      dispatch,
      true,
      true,
    )
  }

  componentWillUnmount() {
    loaderRedux?.onUnMount();
  }

  searchAction = (value) => {
    search(`${endpoints.vehicles}?sold=false`, value, this, null, true)
  }

  render() {
    let {history, loading, vehicles} = this.props;
    const {loadingSearch, search_string, results} = this.state;
    if (results && search_string) vehicles = results;
    if (vehicles == null) vehicles = [];
    return (
      <div className="col-12">
        <div className="row mb-3 justify-content-end">
          <button className="btn btn-primary btn-pill btn" onClick={() => {
            history.push("/vehicles/add")
          }}>
            <i className="icon-plus mr-2"/> Add Vehicle
          </button>
        </div>
        <div className="row mx-auto justify-content-center mb-4">
          <div className="col-lg-8 col-md-10">
            <div className="form-inline my-2 my-lg-0 flex-grow-1">
              <div className="input-group mr-sm-2 flex-grow-1 input-main-border">
                <input onChange={e => {
                  this.setState({
                    search_string: e.target.value
                  })
                  this.searchAction(e.target.value)
                }}
                       className="form-control border-right-0"
                       type="search"
                       placeholder="Search"
                       aria-label="Search"/>
                {loadingSearch ? <div className="input-group-append input-main-border-icon">
                                    <span className="input-group-text bg-transparent" id="basic-addon1">
                                        <div
                                          className="spinner-border spinner-border-sm text-primary"
                                          role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </span>
                </div> : null}
              </div>
              <button onClick={() => this.searchAction(this.state.search_string)}
                      className="btn btn-primary btn-pill my-2 my-sm-0"
                      type="button">Search
              </button>
            </div>
          </div>
        </div>
        {
          vehicles.length < 1 ?
            <div className="text-center p-5 m-5">
              <h3>No Vehicles Found.</h3>
              <button className="btn btn-primary btn-pill btn-lg" onClick={() => {
                history.push("/vehicles/add")
              }}>
                <i className="icon-plus mr-2"/> Add Vehicle
              </button>
            </div> :
            <div className="row">
              {
                vehicles.map((vehicle) => <SingleVehicle key={vehicle.id} vehicle={vehicle}/>)
              }
            </div>
        }
        {loading &&
          <div className="text-center">
            <CircularProgress size={"4rem"}/>
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps)(Imports);