import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../helpers/helper_functions";

export const FETCH_FEATURES = "fetch_features";
export const LOADING_FEATURES = "loading_features";

export const getFeatures = () => {
    return dispatch => {
        dispatch({
            type: LOADING_FEATURES
        })
        makeRequest(GET_REQUEST, `${endpoints.features}`, {}, response => {
            const features = response.data;
            return dispatch({
                features: features,
                type: FETCH_FEATURES
            })
        }, error => {
            showAlert('error', "Error!", parseErrorResponse(error));
           // console.log(error);
        });
    }
}