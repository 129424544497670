import {FETCH_MODELS, LOADING_MODELS} from "./actions";

const modelsState = {
    models: null,
};

const reducer = (state = modelsState, action) => {
    if (action.type === FETCH_MODELS) {
        return {...state, models: action.models, loading: false};
    }
    if (action.type === LOADING_MODELS) {
        return {...state, loading: true};
    }
    return state;
};

export default reducer;