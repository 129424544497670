import {FETCH_STATISTICS, LOADING_STATISTICS} from "./actions";

const statisticsState = {
    statistics: null,
};

const reducer = (state = statisticsState, action) => {
    if (action.type === FETCH_STATISTICS) {
        return {
            ...state,
            statistics: action.statistics,
            loading: false
        };
    }
    if (action.type === LOADING_STATISTICS) {
        return {
            ...state,
            loading: true
        };
    }
    return state;
};

export default reducer;