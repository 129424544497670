import React from "react";
import NavBar from "./navbar";
import {Route, Switch} from "react-router";
import {routes} from '../../routes/routes';
import NotFound from "./404";
import SideBar from "./sidebar";
import {isUserLoggedIn} from "../../helpers/login";
import Login from "../../pages/login";

function Main() {
    return (
        isUserLoggedIn() ? <div>
            <NavBar/>
            <div className="app-body">
                <SideBar/>
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <Switch>
                            {routes.map((route, index) => (
                                <Route
                                    key={index}
                                    path={route.path}
                                    exact={route.exact}
                                    children={({location, history, match}) =>
                                        <route.component location={location}
                                                         history={history}
                                                         match={match}/>
                                    }
                                />
                            ))}
                            <NotFound/>
                        </Switch>
                    </div>
                </div>
            </div>
        </div> : <Login/>
    )
}

export default Main;