import React, {Component} from 'react';
import endpoints from "../../routes/endpoints";
import {onMount} from "../../providers/loader_plain";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../components/Table";
import {vehicleRequestColumns} from "../../providers/vehicle_request/data";
import {search} from "../../providers/search/loader";

class TradeInRequests extends Component {
    state = {
        trade_in_requests: []
    }

    componentDidMount() {
        this.getTradeInRequests();
    }

    getTradeInRequests = () => {
        onMount(this, `${endpoints.sales_requests}?for_sale=true`, "trade_in_requests");
    }

    searchAction = (value) => {
        search(`${endpoints.sales_requests}?for_sale=true`, value, this,"trade_in_requests");
    }

    render() {
        let {loading, trade_in_requests, loadingSearch} = this.state;

        return (
            loading ?
                <div className="text-center my-5 py-5">
                    <CircularProgress/>
                </div> :
                <div className="card">
                    <div className="card-body">
                        <Table
                            caption={"All Trade In Requests"}
                            hideAction={true}
                            actionText={"Add trade_in_request"}
                            columns={vehicleRequestColumns}
                            data={trade_in_requests}
                            loadingSearch={loadingSearch}
                            searchAction={this.searchAction}
                        />
                    </div>
                </div>
        );
    }
}

export default TradeInRequests;