import {FETCH_SUPPLIERS, LOADING_SUPPLIERS} from "./actions";

const suppliersState = {
   suppliers: null,
};

const reducer = (state = suppliersState, action) => {
    if (action.type === FETCH_SUPPLIERS) {
        return {...state, suppliers: action.suppliers, loading: false};
    }
    if (action.type === LOADING_SUPPLIERS) {
        return {...state, loading: true};
    }
    return state;
};

export default reducer;