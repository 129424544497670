import React, {Component} from 'react';
import {makeRequest} from "../../../helpers/network_utils";
import {POST_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import Swal from "sweetalert2";
import {handleChange} from "../../../helpers/helper_functions";

const $ = window.$;

class SuppliersAdd extends Component {

    state = {
        name: '',
        country: '',
        loading: false,
        errors: {}
    }
    handleSubmit = () => {
        this.setState({
            loading: true
        })
        const {name, country} = this.state;
        makeRequest(POST_REQUEST, endpoints.suppliers, {name: name, country: country}, () => {
            Swal.fire(
                'Success!',
                'Supplier was saved successfully!',
                'success'
            ).then(() => {
                this.props.getSuppliers();
                $("#addSupplierModal").modal('toggle');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
            });
        }, (error) => {
            this.setState({
                errors: error.response.data
            })
        }, () => {
            this.setState({loading: false})
        })
    }

    render() {
        const {errors, loading} = this.state;

        return (
            <div className="modal fade" id="addSupplierModal" role="dialog" aria-labelledby="exampleModalCenterTitle"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Add Supplier</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12">
                                <form>
                                    <div className="form-group">
                                        <label>Country</label>
                                        <select name="country" onChange={(e) => handleChange(e, this)}
                                                className="form-control">
                                            <option>Select Country</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Britain">Britain</option>
                                            <option value="Japan">Japan</option>
                                            <option value="South Africa">South Africa</option>
                                        </select>
                                        {errors.country &&
                                        <p className="small text-danger">{errors.country[0]}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Supplier Name</label>
                                        <input onChange={(e) => handleChange(e, this)} type="text"
                                               className="form-control" name="name"/>
                                        {errors.name && <p className="small text-danger">{errors.name[0]}</p>}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button onClick={this.handleSubmit} type="button" className="btn btn-primary"
                                    disabled={loading}>{loading ? "Loading..." : "Save"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default SuppliersAdd;