import React from "react";
import {makeRequest} from "../../../helpers/network_utils";
import {DELETE_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../../helpers/helper_functions";
import CircularProgress from "@material-ui/core/CircularProgress";

class SingleImage extends React.Component {

    state = {
        loading: false
    }

    handleDelete = () => {
        this.setState({
            loading: true
        })
        const {image} = this.props;
        makeRequest(DELETE_REQUEST, `${endpoints.vehicle_images}${image.id}`, {}, () => {
            this.props.getImages();
        }, (error) => {
            showAlert('error', "Error", parseErrorResponse(error))
        }, () => {
            this.setState({
                loading: false
            })
        })
    }

    render() {
        const {image} = this.props;
        const {loading} = this.state;
        return (
            <div className="col-md-4">
                <div className="card card-shadow mb-4 bg-white text-black">
                    <div className="card-body">
                        <div className="row d-flex align-items-center">
                            <div className="col-12 text-center">
                                {loading ?
                                    <CircularProgress size={"10rem"}/> :
                                    <img
                                        className="imi d-inline-block mb-3 mb-lg-0 img-responsive" src={image.image}
                                        srcSet={image.image} alt="car"/>
                                }
                                <div className="text-right">
                                    <button onClick={this.handleDelete} className="btn btn-sm btn-danger my-2"><span
                                        className="icon-trash"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SingleImage;