import React, {Component} from 'react';
import {connect} from "react-redux";
import {getMakes} from "../../../redux/makes/actions";
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import {numberWithCommas, parseErrorResponse, showAlert} from "../../../helpers/helper_functions";
import {getSuppliers} from "../../../redux/suppliers/actions";
import {getFeatures} from "../../../redux/features/actions";
import {conditions, getYearsArray, interiors} from "../../../providers/vehicles/data";

const mapStateToProps = state => ({
  makes: state.makesState.makes,
  models: state.modelsState.models,
  suppliers: state.suppliersState.suppliers,
  features: state.featuresState.features,
  loading: state.modelsState.loading
});

const mapDispatchToProps = dispatch => ({
  getMakes: () => dispatch(getMakes()),
  getFeatures: () => dispatch(getFeatures()),
  getSuppliers: () => dispatch(getSuppliers()),
});

class AddVehicle extends Component {

  state = {
    // year: 2020,
    loading: false, loadingModels: false, models: [], suppliers: [], features: []
  }

  componentDidMount() {
    /*new Rolldate({
        el: '#year_input',
        format: 'YYYY',
        confirm: (date) => {
            this.setState({
                year: date
            })
            let e = {
                target: {
                    value: date,
                    name: "year"
                }
            }
            this.props.handleChange(e, this);
        },
        lang: {
            title: 'Select A Date',
            cancel: 'Cancel',
            confirm: 'Confirm',
            year: '',
            month: '',
            day: '',
            hour: '',
            min: '',
            sec: ''
        }
    })*/
    const {getMakes, makes, getFeatures, features} = this.props;
    if (makes == null) getMakes();
    if (features == null) getFeatures();
    /* if (suppliers == null) getSuppliers();*/
  }

  getModels = (id) => {
    this.setState({loadingModels: true});
    makeRequest(GET_REQUEST, `${endpoints.models}?make=${id}`, {}, response => {
      this.setState({models: response.data})
    }, error => {
      showAlert('error', 'Error', parseErrorResponse(error))
    }, () => {
      this.setState({loadingModels: false})
    })
  }

  handleFeatureChange = (e, feature) => {
    const {data, handleChange} = this.props;
    let newValue = data?.features || [];

    if (e.target.checked) newValue.push(feature); else newValue = this.deleteFeature(feature)
    let newE = {
      target: {
        value: newValue, name: "features"
      },
    }
    handleChange(newE, this);
  }

  deleteFeature = feature => {
    const {data} = this.props;
    let newValue = data?.features || [];
    let newFeature = [];
    for (let i = 0; i < newValue.length; i++) {
      if (newValue[i] !== feature) newFeature.push(newValue[i]);
    }
    return newFeature;
  }

  featureExists = feature => {
    const {data} = this.props;
    let features = data?.features || [];
    for (let i = 0; i < features.length; i++) {
      if (features[i] === feature) return true;
    }
    return false;
  }


  render() {
    const {models, loadingModels} = this.state;
    const {makes, handleSubmit, handleChange, errors, data, editing, features} = this.props;
    return (<div className="col-12">
      <form onSubmit={handleSubmit}>
        <div className="card card-body shadow-sm my-3 py-5">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Make <b className="text-danger">*</b></label>
                <select onChange={e => {
                  this.getModels(e.target.value)
                }} className="form-control">
                  {data?.model?.make && editing ? <option value={data?.model?.make?.id}>
                    {data?.model?.make?.name}
                  </option> : <option>Select Make</option>}
                  {makes ? makes.map((make) => data?.model?.make?.id !== make?.id ?
                    <option key={make.id} value={make.id}>
                      {make.name}
                    </option> : null) : null}
                </select>
                {errors.make && <p className="small text-danger">{errors.make[0]}</p>}
              </div>
              <div className="form-group">
                <label>Drive Type <b className="text-danger">*</b></label>
                <select name="drive_type"
                        onChange={(e) => handleChange(e, this)}
                        className="form-control">
                  {data?.drive_type && editing ? <option value={data?.drive_type}>
                    {data?.drive_type}
                  </option> : <option>Select Drive Type</option>}
                  <option value="2WD">Two Wheel Drive (2WD)</option>
                  <option value="4WD">Four Wheel Drive (4WD)</option>
                  <option value="AWD">All Wheel Drive (AWD)</option>
                </select>
                {errors.drive_type && <p className="small text-danger">{errors.drive_type[0]}</p>}
              </div>
              <div className="form-group">
                <label>Year <b className="text-danger">*</b></label>
                <select name="year"
                        onChange={(e) => handleChange(e, this)}
                        className="form-control">
                  {data?.year && editing ? <option value={data?.year}>
                    {data?.year}
                  </option> : <option>Select Year</option>}
                  {getYearsArray().map(year => <option key={year}
                                                       value={year}>{year}</option>)}
                </select>
                {errors.year && <p className="small text-danger">{errors.year[0]}</p>}
              </div>
              <div className="form-group">
                <label>Fuel Type <b className="text-danger">*</b></label>
                <select name="fuel_type"
                        onChange={(e) => handleChange(e, this)}
                        className="form-control">
                  {data?.fuel_type && editing ? <option value={data?.fuel_type}>
                    {data?.fuel_type}
                  </option> : <option>Select Fuel Type</option>}
                  <option value="Diesel">Diesel</option>
                  <option value="Petroleum">Petrol</option>
                </select>
                {errors.fuel_type && <p className="small text-danger">{errors.fuel_type[0]}</p>}
              </div>
              <div className="form-group">
                <label>For Import<b className="text-danger">*</b></label>
                <select name="for_import"
                        onChange={(e) => handleChange(e, this)}
                        className="form-control">
                  {data?.for_import && editing ? <option value={data?.for_import}>
                    {data?.for_import}
                  </option> : <option>Select Option</option>}
                  <option value="True">True</option>
                  <option value="False">False</option>
                </select>
                {errors.fuel_type && <p className="small text-danger">{errors.fuel_type[0]}</p>}
              </div>
              <div className="form-group">
                <label>Engine Size (in CC) <b className="text-danger">*</b></label>
                <input name="engine_size" onChange={(e) => handleChange(e, this)} type="number"
                       className="form-control"
                       value={data?.engine_size ?? ""}
                       aria-describedby="engine size"/>
                {errors.engine_size && <p className="small text-danger">{errors.engine_size[0]}</p>}
              </div>
              <div className="form-group">
                <label>Vehicle Interior <b className="text-danger">*</b></label>
                <select name="interior"
                        onChange={(e) => handleChange(e, this)}
                        className="form-control">
                  {data?.interior && editing ? <option value={data?.interior}>
                    {data?.interior}
                  </option> : <option>Select Interior</option>}
                  {interiors.map(interior => <option key={interior.id}
                                                     value={interior.name}>{interior.name}</option>)}
                </select>
                {errors.interior && <p className="small text-danger">{errors.interior[0]}</p>}
              </div>
              <div className="form-group">
                <label>Number Plate</label>
                <input onChange={(e) => handleChange(e, this)} type="text"
                       className="form-control"
                       value={data?.number_plate?.toUpperCase() ?? ""}
                       name="number_plate"
                       aria-describedby="price"/>
                {errors.number_plate && <p className="small text-danger">{errors.number_plate[0]}</p>}
              </div>
              <div className="form-group">
                <label>Selling Price <b className="text-danger">*</b></label>
                <input onChange={(e) => handleChange(e, this, true)} type="text"
                       className="form-control"
                       value={numberWithCommas(`${data?.selling_price ?? ""}`.replace(/,/g, "") ?? "")}
                       name="selling_price"
                       aria-describedby="selling price"/>
                {errors.selling_price && <p className="small text-danger">{errors.selling_price[0]}</p>}
              </div>
              <div className="form-group">
                <label>Features</label>
                <div className="row mx-auto">
                  {features ? features.map((feature) => <div key={feature.id}
                                                             className="form-check col-xl-4 col-md-6">
                    <input onChange={e => this.handleFeatureChange(e, feature.id)}
                           checked={this.featureExists(feature.id)}
                           className="form-check-input" type="checkbox" value=""/>
                    <label className="form-check-label" htmlFor="defaultCheck1">
                      {feature.name}
                    </label>
                  </div>) : null}
                </div>
                {errors.features && <p className="small text-danger">{errors.features[0]}</p>}
              </div>
              <div className="form-group">
                <label>Sales Person Phone Number<b className="text-danger">*</b></label>
                <input name="sales_person_phone_number"
                       onChange={(e) => handleChange(e, this)} type="text"
                       className="form-control"
                       value={data?.sales_person_phone_number ?? ""}
                       aria-describedby="engine size"/>
                {errors.sales_person_phone_number &&
                  <p className="small text-danger">{errors.sales_person_phone_number[0]}</p>}
              </div>
              {/* <div className="form-group">
                                    <label>Sales Person Name <b className="text-danger">*</b></label>
                                    <input name="sales_person_name"
                                           onChange={(e) => handleChange(e, this)} type="text"
                                           className="form-control"
                                           value={data?.sales_person_name ?? ""}
                                           aria-describedby="engine size"/>
                                    {errors.sales_person_name && <p className="small text-danger">{errors.sales_person_name[0]}</p>}
                                </div>*/}
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Model <b className="text-danger">*</b></label>
                <select name="model"
                        onChange={(e) => handleChange(e, this)} className="form-control">
                  {loadingModels ? <option value={""}>Loading...</option> : <></>}
                  {data?.model && editing ? <option value={data?.model?.id}>
                    {data?.model?.name}
                  </option> : <option>Select Model</option>}
                  {models.map((model) => data?.supplier?.id !== model?.id ? <option key={model.id} value={model.id}>
                    {model.name}
                  </option> : null)}
                </select>
                {errors.model && <p className="small text-danger">{errors.model[0]}</p>}
              </div>
              <div className="form-group">
                <label>Body Type <b className="text-danger">*</b></label>
                <select name="body_type"
                        onChange={(e) => handleChange(e, this)}
                        className="form-control">
                  {data?.body_type && editing ? <option value={data?.body_type}>
                    {data?.body_type}
                  </option> : <option>Select Body Type</option>}
                  <option value="SUV">4WD's and SUVs</option>
                  <option value="Saloon">Saloon</option>
                  <option value="Hatchback">Hatchback</option>
                  <option value="Pickup">Pickup</option>
                  <option value="Wagon">Station Wagons</option>
                  <option value="Minivan">Motorbikes</option>
                  <option value="Minivan">Minivan</option>
                  <option value="Minivan">Convertibles</option>
                  <option value="Buses and Vans"> Buses and Vans</option>
                  <option value="Mini SUV">Mini SUV's</option>
                  <option value="Coupes">Coupes</option>
                  <option value="Quad Bikes">Quad Bikes</option>
                </select>
                {errors.body_type && <p className="small text-danger">{errors.body_type[0]}</p>}
              </div>
              <div className="form-group">
                <label>Color <b className="text-danger">*</b></label>
                <select name="color"
                        onChange={(e) => handleChange(e, this)}
                        className="form-control">
                  {data?.color && editing ? <option value={data?.color}>
                    {data?.color}
                  </option> : <option>Select Color</option>}
                  <option value="black">Black</option>
                  <option value="beige">Beige</option>
                  <option value="burgundy">Burgundy</option>
                  <option value="bronze">Bronze</option>
                  <option value="brown">Brown</option>
                  <option value="gold">Gold</option>
                  <option value="gray">Grey</option>
                  <option value="green">Green</option>
                  <option value="indigo">Indigo</option>
                  <option value="maroon">Maroon</option>
                  <option value="navy blue">Navy Blue</option>
                  <option value="orange">Orange</option>
                  <option value="pink">Pink</option>
                  <option value="purple">Purple</option>
                  <option value="red">Red</option>
                  <option value="silver">Silver</option>
                  <option value="white">White</option>
                  <option value="yellow">Yellow</option>
                  <option value="blue">Blue</option>
                  <option value="dark_grey">Dark Grey</option>
                  <option value="dark_green">Dark Green</option>
                  <option value="pearl">Pearl</option>
                </select>
                {errors.color && <p className="small text-danger">{errors.color[0]}</p>}
              </div>
              <div className="form-group">
                <label>Transmission <b className="text-danger">*</b></label>
                <select name="transmission_type" onChange={(e) => handleChange(e, this)}
                        className="form-control">
                  {data?.transmission_type && editing ? <option value={data?.transmission_type}>
                    {data?.transmission_type}
                  </option> : <option>Select Transmission Type</option>}
                  <option value="Automatic">Automatic</option>
                  <option value="Manual">Manual</option>
                </select>
                {errors.transmission_type && <p className="small text-danger">{errors.transmission_type[0]}</p>}
              </div>
              <div className="form-group">
                <label>Mileage (in KM) <b className="text-danger">*</b></label>
                <input onChange={(e) => handleChange(e, this, true)} type="text"
                       className="form-control"
                       value={numberWithCommas(`${data?.mileage ?? ""}`.replace(/,/g, "") ?? "")}
                       name="mileage"
                       aria-describedby="price"/>
                {errors.mileage && <p className="small text-danger">{errors.mileage[0]}</p>}
              </div>
              <div className="form-group">
                <label>Condition <b className="text-danger">*</b></label>
                <select name="condition"
                        onChange={(e) => handleChange(e, this)}
                        className="form-control">
                  {data?.condition && editing ? <option value={data?.condition}>
                    {data?.condition}
                  </option> : <option>Select Condition</option>}
                  {conditions.map(condition => <option key={condition.id}
                                                       value={condition.name}>{condition.name}</option>)}
                </select>
                {errors.condition && <p className="small text-danger">{errors.condition[0]}</p>}
              </div>
              <div className="form-group">
                <label>Sales Person Email <b className="text-danger">*</b></label>
                <input name="sales_person_email"
                       onChange={(e) => handleChange(e, this)} type="email"
                       className="form-control"
                       value={data?.sales_person_email ?? ""}
                       aria-describedby="engine size"/>
                {errors.sales_person_email && <p className="small text-danger">{errors.sales_person_email[0]}</p>}
              </div>
              <div className="form-group">
                <label>Selling Price Currency <b className="text-danger">*</b></label>
                <input onChange={(e) => handleChange(e, this)} type="text"
                       className="form-control"
                       value={data?.currency}
                       name="currency"
                       aria-describedby="currency"/>
                {errors.currency && <p className="small text-danger">{errors.currency[0]}</p>}
              </div>
              <div className="form-group">
                <label>Description <b className="text-danger">*</b></label>
                <textarea value={data.description || ""} onChange={(e) => handleChange(e, this)}
                          className="form-control" name="description"/>
                {errors.description && <p className="small text-danger">{errors.description[0]}</p>}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddVehicle);