export const interiors = [
    {
        id: 1,
        name: "Leather"
    },
    {
        id: 2,
        name: "Alcantara"
    },
    {
        id: 3,
        name: "Cloth"
    },
]

export const conditions = [
    {
        id: 1,
        name: "Foreign used"
    },
    {
        id: 2,
        name: "Locally used"
    },
    {
        id: 3,
        name: "New"
    },
    {
        id: 4,
        name: "Ready for Import"
    },
]

export const getYearsArray = () => {
    let startYear = 2000;
    let endYear = new Date().getFullYear();
    let years = [];

    while (startYear <= endYear)
        years.push(startYear++)
    return years;
}