import {FETCH_CLIENTS, LOADING_CLIENTS} from "./actions";

const clientsState = {
    clients: null,
};

const reducer = (state = clientsState, action) => {
    if (action.type === FETCH_CLIENTS) {
        return {...state, clients: action.clients, loading: false};
    }
    if (action.type === LOADING_CLIENTS) {
        return {...state, loading: true};
    }
    return state;
};

export default reducer;