import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../helpers/helper_functions";

export const FETCH_STATISTICS = "fetch_statistics";
export const LOADING_STATISTICS = "loading_statistics";

export const getStatistics = () => {
    return dispatch => {
        dispatch({
            type: LOADING_STATISTICS
        })
        makeRequest(GET_REQUEST, endpoints.analytics, {}, response => {
            const statistics = response.data;
            return dispatch({
                statistics: statistics,
                type: FETCH_STATISTICS
            })
        }, error => {
            showAlert('error', 'Error', parseErrorResponse(error))
        })
    }
}