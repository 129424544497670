import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {getVehicles} from "../../redux/vehicles/actions";
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../components/Table";

const columns = [
    {
        dataField: 'make',
        text: 'Make',
        formatter: (model,row) => `${row?.model?.make?.name || "-"}`
    },
    {
        dataField: 'model',
        text: 'Model',
        formatter: model => `${model?.name || "-"}`
    },
    {
        dataField: 'chassis_number',
        text: 'Chassis Number',
        formatter: chassis => `${chassis || "-"}`
    },
    {
        dataField: 'number_plate',
        text: 'Registration Number',
        formatter: numberPlate => `${numberPlate || "-"}`
    },
    /*{
        dataField: 'bought',
        text: 'Sold',
        formatter: sold => <span
            className={`rounded-pill px-3 py-1 ${sold ? "badge-success" : "badge-info"}`}>{sold ? "Yes" : "No"}</span>
    },*/
    /*{
        dataField: 'buying_price',
        text: 'Buying Price',
        formatter: price => `KES ${numberWithCommas(price)}`
    },*/
    {
        dataField: 'id',
        text: 'Action',
        formatter: id => <Link to={`/vehicles/${id}`} className="btn btn-sm btn-success">View</Link>
    },
]

const mapStateToProps = state => (state.vehiclesState);

const mapDispatchToProps = dispatch => ({
    getVehicles: () => dispatch(getVehicles()),
});


class Vehicles extends Component {

    componentDidMount() {
       if (this.props.vehicles == null)
           this.props.getVehicles();
    }

    handleAddVehicle = () => {
        const {history} = this.props;
        history.push("/vehicles/add");
    }

    render() {
        let {loading, vehicles} = this.props;
        if (vehicles == null) vehicles = [];
        vehicles.slice(0, 4);

        return (
            loading ?
                <div className="text-center">
                    <CircularProgress/>
                </div> :
                <div className="card">
                    <div className="card-body">
                        <Table
                            caption={"Recently Added Vehicles"}
                            actionText={"Add Vehicle"}
                            hideAction={true}
                            actionFunc={this.handleAddVehicle}
                            columns={columns}
                            data={vehicles}
                        />
                    </div>
                </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Vehicles);