import React, {Component} from 'react';
import endpoints from "../../routes/endpoints";
import {onMount} from "../../providers/loader_plain";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../components/Table";
import {vehicleRequestColumns} from "../../providers/vehicle_request/data";
import {search} from "../../providers/search/loader";

class ImportRequests extends Component {
    state = {
        imports_requests: []
    }

    componentDidMount() {
        this.getImportsRequests();
    }

    getImportsRequests = () => {
        onMount(this, `${endpoints.sales_requests}?for_sale=true`, "imports_requests");
    }

    searchAction = (value) => {
        search(`${endpoints.sales_requests}?for_sale=true`, value, this, "import_requests");
    }

    render() {
        let {loading, imports_requests, loadingSearch} = this.state;

        return (
            loading ?
                <div className="text-center my-5 py-5">
                    <CircularProgress/>
                </div> :
                <div className="card">
                    <div className="card-body">
                        <Table
                            caption={"All Import Requests"}
                            hideAction={true}
                            actionText={"Add imports_request"}
                            columns={vehicleRequestColumns}
                            data={imports_requests}
                            loadingSearch={loadingSearch}
                            searchAction={this.searchAction}
                        />
                    </div>
                </div>
        );
    }
}

export default ImportRequests;