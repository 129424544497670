import React, {Component} from 'react';
import {onMount} from "../../providers/loader_plain";
import endpoints from "../../routes/endpoints";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeRequest} from "../../helpers/network_utils";
import {PATCH_REQUEST} from "../../values/globals";

class MessageDetails extends Component {

    state = {
        message: null
    }

    componentDidMount() {
        this.getTradeInRequests();
        this.markAsRead();
    }

    getTradeInRequests = () => {
        const {id} = this.props.match.params;
        onMount(this, `${endpoints.messages}${id}`, "message");
    }

    markAsRead = () => {
        const {id} = this.props.match.params;
        makeRequest(PATCH_REQUEST, `${endpoints.messages}${id}/`, {read: true});
    }

    render() {
        let {
            loading,
            message
        } = this.state;
        return (
            loading ?
                <div className="text-center my-5 py-5">
                    <CircularProgress/>
                </div> :
                message ?
                    <div className="row">
                        <div className="col-4 pr-2">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h5>Client Details</h5>
                                    <hr className="my-1"/>
                                    <SingleRow title={"Name"} value={message.name}/>
                                    <SingleRow title={"Phone Number"} value={message.phone_number}/>
                                    <SingleRow title={"Email"} value={message.email}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-8 pl-2">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h5>Message</h5>
                                    <hr className="my-1"/>
                                    <SingleRow title={"Subject"} value={message.subject}/>
                                    <SingleRow title={"Body"} value={message.text}/>
                                </div>
                            </div>
                        </div>
                    </div> :
                    <div className="text-center py-5 my-5">
                        <p className="font-weight-bold">Could not load message</p>
                    </div>
        );
    }
}

function SingleRow(props) {
    return (
        <div className="flex-column">
            <p className="font-weight-bold m-0"> {props.title} </p>
            <p> {props.value} </p>
        </div>
    )
}

export default MessageDetails;