import {Link} from "react-router-dom";
import React from "react";

export const DOMAIN = "https://gigi-api-bvxtqx2c3a-uc.a.run.app/api/v1";

export const USER_OBJECT = "user_object";

export const JWT_TOKEN = "jwt_token";

//TODO: Remove this
export const REFRESH_TOKEN = "refresh_token";

export const GET_REQUEST = "get_request";
export const POST_REQUEST = "post_request";
export const PUT_REQUEST = "put_request";
export const PATCH_REQUEST = "patch_request";
export const DELETE_REQUEST = "delete_request";
export const SUBDOMAIN_NAME = "subdomain_name";
export const ORGANIZATION_DATA = "organization_data";


export const packageColumns = [
    {
        dataField: 'code',
        text: 'Shipment Code',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'recipient',
        text: 'Recipient',
        formatter: row => `${row.name}`
    },
    {
        dataField: 'weight',
        text: 'Weight',
    },
    /*{
        dataField: 'destination',
        text: 'Destination',
        formatter: row => `${row.destination}`
    },*/
    {
        dataField: 'description',
        text: 'Description',
    },
    /*  {
          dataField: 'action',
          text: 'Dispatch',
          formatter: id => <Link to={`/received-packages/${id}`} className="btn btn-success btn-sm">Dispatch</Link>
      },*/
    {
        dataField: 'id',
        text: 'Action',
        formatter: id => <Link to={`/all-packages/${id}`} className="btn btn-primary btn-sm">View</Link>
    },
];