import React, {Component} from 'react';
import SingleImage from "./single_image";
import AddImageModal from "./add_image_modal";
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../../helpers/helper_functions";
import CircularProgress from "@material-ui/core/CircularProgress";

class AddImages extends Component {

    state = {
        loading: false,
        images: []
    }

    componentDidMount() {
        this.getImages();
    }

    getImages = () => {
        const {id} = this.props.match.params;
        const {history} = this.props;
        this.setState({loading: true})
        makeRequest(GET_REQUEST, `${endpoints.vehicle_images}?vehicle__slug=${id}`, {}, response => {
            this.setState({
                images: response.data
            })
        }, error => {
            showAlert('error', 'Error', parseErrorResponse(error))
            history.push('/vehicles');
        }, () => {
            this.setState({
                loading: false
            })
        })
    }

    render() {
        const {loading, images} = this.state;
        const {match, history} = this.props;
        return (
            <div style={{minHeight: "50vh"}}>
                <AddImageModal match={match} history={history} getImages={this.getImages}/>
                {loading ?
                    <div className="text-center">
                        <CircularProgress/>
                    </div> :
                    <div className="col-12">
                        <div className="row align-items-center">

                            {images.map((image) => <SingleImage key={image.id} image={image}
                                                                getImages={this.getImages}/>)}
                            <div className="p-4 col">
                                <i style={{fontSize: "7rem"}} data-toggle="modal" data-target="#addImageModal"
                                   className="icon-plus icon-button"/>
                            </div>
                        </div>
                    </div>
                }

            </div>
        );
    }
}

export default AddImages;