import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../helpers/helper_functions";

export const FETCH_VEHICLES = "fetch_vehicles";
export const LOADING_VEHICLES = "loading_vehicles";

export const getVehicles = () => {
    return dispatch => {
        dispatch({
            type: LOADING_VEHICLES
        })
        makeRequest(GET_REQUEST, `${endpoints.vehicles}?sold=false`, {}, response => {
            const suppliers = response.data;
            return dispatch({
                vehicles: suppliers,
                type: FETCH_VEHICLES
            })
        }, error => {
            showAlert('error', "Error!", parseErrorResponse(error));
            console.log(error);
        });
    }
}

export const getSoldVehicles = () => {
    return dispatch => {
        dispatch({
            type: LOADING_VEHICLES
        })
        makeRequest(GET_REQUEST, `${endpoints.vehicles}?sold=true`, {}, response => {
            const suppliers = response.data;
            return dispatch({
                sold_vehicles: suppliers,
                type: FETCH_VEHICLES
            })
        }, error => {
            showAlert('error', "Error!", parseErrorResponse(error));
            console.log(error);
        });
    }
}

export const getTradeInVehicles = () => {
    return dispatch => {
        dispatch({
            type: LOADING_VEHICLES
        })
        makeRequest(GET_REQUEST, `${endpoints.vehicles}?source=Trade in`, {}, response => {
            const suppliers = response.data;
            return dispatch({
                vehicles: suppliers,
                type: FETCH_VEHICLES
            })
        }, error => {
            showAlert('error', "Error!", parseErrorResponse(error));
            console.log(error);
        });
    }
}