import {FETCH_FEATURES, LOADING_FEATURES} from "./actions";

const featuresState = {
    features: null,
};

const reducer = (state = featuresState, action) => {
    if (action.type === FETCH_FEATURES) {
        return {...state, features: action.features, loading: false};
    }
    if (action.type === LOADING_FEATURES) {
        return {...state, loading: true};
    }
    return state;
};

export default reducer;