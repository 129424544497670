import Dashboard from "../pages/dashboard";
import Login from "../pages/login";
import AdminSettings from "../pages/admin_settings";
import Logout from "../pages/login/logout";
import Imports from "../pages/imports";
import VehicleDetails from "../pages/vehicle/details";
import VehicleMain from "../pages/imports/vehicle/main";
import Settings from "../pages/settings";
import SalesRequests from "../pages/sales_requests";
import ImportRequests from "../pages/import_requests";
import TradeInRequests from "../pages/trade_in_requests";
import RequestDetails from "../pages/sales_requests/details";
import Enquiries from "../pages/enquiries";
import EnquiryDetails from "../pages/enquiries/details";
import MessageDetails from "../pages/messages/details";
import Messages from "../pages/messages";
import SoldVehicles from "../pages/sold_vehicles";

const urls = {
    index: {
        path: '/',
        exact: true,
        component: Dashboard,
    },

    logout: {
        path: '/logout',
        exact: true,
        component: Logout,
    },

    login: {
        path: "/login",
        exact: true,
        component: Login
    },

    admin_settings: {
        path: "/admin/settings",
        exact: true,
        component: AdminSettings
    },

    settings: {
        path: "/settings",
        exact: false,
        component: Settings
    },

    vehicles_list: {
        path: "/vehicles",
        exact: true,
        component: Imports
    },

    vehicle_details: {
        path: "/vehicles/:id",
        exact: true,
        component: VehicleDetails
    },

    vehicle_edit: {
        path: "/vehicles/edit/:id",
        exact: true,
        component: VehicleMain
    },

    vehicle_add: {
        path: "/vehicles/add",
        exact: true,
        component: VehicleMain
    },

    vehicle_images_add: {
        path: "/vehicles/images/add/:id",
        exact: true,
        component: VehicleMain
    },

    sales_requests: {
        path: "/sales-requests",
        exact: true,
        component: SalesRequests
    },

    import_requests: {
        path: "/import-requests",
        exact: true,
        component: ImportRequests
    },

    trade_in_requests: {
        path: "/trade-in-requests",
        exact: true,
        component: TradeInRequests
    },

    vehicle_requests_details: {
        path: "/vehicle-requests/:id",
        exact: true,
        component: RequestDetails
    },

    sold_vehicles: {
        path: "/sold-vehicles",
        exact: true,
        component: SoldVehicles
    },

    enquiries: {
        path: "/enquiries",
        exact: true,
        component: Enquiries
    },

    enquiry_details: {
        path: "/enquiries/:id",
        exact: true,
        component: EnquiryDetails
    },

    messages: {
        path: "/messages",
        exact: true,
        component: Messages
    },

    message_details: {
        path: "/messages/:id",
        exact: true,
        component: MessageDetails
    },

    /*sold_vehicles: {
        path: "/sold-vehicles",
        exact: true,
        component: SoldVehicles
    },*/


    /*

    expenses: {
        path: "/expenses",
        exact: true,
        component: Expenses
    },

    expense_details: {
        path: "/expenses/:id",
        exact: true,
        component: ExpenseDetails
    },

    clients: {
        path: "/clients",
        exact: true,
        component: Clients
    },

    client_details: {
        path: "/clients/:id",
        exact: true,
        component: ClientDetails
    },

    sales_people: {
        path: "/sales-people",
        exact: true,
        component: SalesPeople
    },

    sales_person_details: {
        path: "/sales-people/:id",
        exact: true,
        component: SalesPersonDetails
    }*/

}

export default urls;