import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {getUserObject} from "../../helpers/login";
import logo from '../../assets/img/logo.png';

class Navbar extends Component {

    state = {
        time: new Date().toLocaleTimeString(),
    }

    componentDidMount() {
        setInterval(() => {
            this.setState({
                time: new Date().toLocaleTimeString(),
            });
        }, 1000);
    }

    render() {
        const {time} = this.state;
        const user = getUserObject();
        return (
            <header className="app-header">
                <div className="branding-wrap">
                    <span style={{cursor: "pointer"}}
                          className="nav-link mt-2 float-left js_left-nav-toggler pos-fixed">
                        <i className=" ti-align-right"/>
                    </span>

                    <div className="navbar-brand pos-fixed">
                        <a className="" href="/">
                            <img src={logo} srcSet={logo} className="navbar-logo"
                                 alt="Logo"/>
                        </a>
                    </div>
                </div>
                <ul className="header-links hide-arrow navbar mt-3">
                    <li className="nav-item d-md-block d-none mr-5">
                        <h3 className="mb-0 mr-5 ">Data Management
                            System</h3>
                    </li>

                    <li className="nav-item d-md-flex d-none">
                        <span className="text-muted">
                            <i className="fa fa-clock-o"/> {time}
                        </span>
                        <span className="nav-item mx-3">|</span>
                        <Link className="nav-link p-0" to="/settings/profile">
                            <span>
                                <i className="fa fa-user mr-1"/>
                                {user.first_name} {user.last_name}
                            </span>
                        </Link>

                        <span className="nav-item mx-3">|</span>
                        <Link className="nav-link p-0" to="/logout">
                            <span>
                                <i className="fa fa-sign-out mr-1"/>
                                Logout
                            </span>
                        </Link>
                    </li>

                    <li className="nav-item dropdown d-block d-md-none">
                        <a className="nav-link dropdown-toggle" id="userNav" href="/#" data-toggle="dropdown"
                           aria-expanded="false">
                            <div className="user-thumb">
                                <img className="rounded-circle" src="/assets/img/avatar/avatar2.jpg" alt=""/>
                            </div>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userNav">
                            <div className="dropdown-item- px-3 py-2">
                                <img className="rounded-circle mr-2" src="/assets/img/avatar/avatar2.jpg" width="35"
                                     alt=""/>
                                <span className="text-muted">{user.first_name} {user.last_name}</span>
                            </div>
                            <div className="dropdown-divider"/>
                            <Link className="dropdown-item" to="/settings/profile">My Profile</Link>
                            <Link className="dropdown-item" to="/logout">Logout</Link>
                        </div>
                    </li>
                </ul>
            </header>
        );
    }
}

export default Navbar;