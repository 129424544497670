import React from "react";

function Sales() {
    return (
        <div className="tab-pane">

        </div>
    );
}

export default Sales;