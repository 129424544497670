import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Profile from "./profile";
import Models from "./models";
import Makes from "./makes";
import Suppliers from "./suppliers";
import Features from "./features";

const pages = {
    profile: "/settings/profile",
    models: "/settings/models",
    makes: "/settings/makes",
    suppliers: "/settings/suppliers",
    features: "/settings/features",
}

class Settings extends Component {

    render() {
        const {location} = this.props;
        const currentPath = location.pathname;
        return (
            <div>
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="profile-nav-links">
                                    <ul className="nav f12">
                                        <li className="nav-item">
                                            <Link className={`nav-link ${currentPath === pages.profile && 'active'}`}
                                                  to="/settings/profile">PROFILE</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={`nav-link ${currentPath === pages.makes && 'active'}`}
                                                  to="/settings/makes">MAKES</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={`nav-link ${currentPath === pages.models && 'active'}`}
                                                  to="/settings/models">MODELS</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={`nav-link ${currentPath === pages.suppliers && 'active'}`}
                                                  to="/settings/suppliers">SUPPLIERS</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={`nav-link ${currentPath === pages.features && 'active'}`}
                                                  to="/settings/features">FEATURES</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                {currentPath === pages.profile && <Profile/>}
                                {currentPath === pages.models && <Models/>}
                                {currentPath === pages.makes && <Makes/>}
                                {currentPath === pages.suppliers && <Suppliers/>}
                                {currentPath === pages.features && <Features/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Settings;