import React, {Component} from 'react';
import Table from "../../../components/Table";
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST, PATCH_REQUEST} from "../../../values/globals";
import Swal from "sweetalert2";
import {parseErrorResponse, showAlert} from "../../../helpers/helper_functions";
import CircularProgress from "@material-ui/core/CircularProgress";
import endpoints from "../../../routes/endpoints";
import UserAdd from "./add";

const $ = window.$;

let self;
const columns = [
    {
        dataField: 'count',
        text: '#',
        formatter: (field, row, index) => `${index + 1}`
    },
    {
        dataField: 'first_name',
        text: 'First Name',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'last_name',
        text: 'Last Name',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'email',
        text: 'Email',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'active',
        text: 'Active',
        formatter: active => <span
            className={`badge ${active ? "badge-success" : "badge-danger"}`}>{active ? "Active" : "inactive"}</span>
    },
    {
        dataField: 'id',
        text: 'Action',
        formatter: (id, row) => {
            const loading = self?.state?.loadingDelete && self?.state?.current_id === id;
            return <button onClick={e => {
                e.preventDefault();
                if (self)
                    self.handleDelete(row);
            }} disabled={loading} className={`btn btn-sm ${row.active ? "btn-danger" : "btn-success"}`}>{loading ?
                "Loading" : row.active ? "De-Activate" : "Activate"}</button>
        }
    },
]

class UsersList extends Component {

    state = {
        users: [],
        loading: false,
        current_id: null
    }

    componentDidMount() {
        this.getUsers();
        self = this;
    }

    getUsers = () => {
        this.setState({loading: true});
        makeRequest(GET_REQUEST, endpoints.users, {}, response => {
            const users = response.data;
            this.setState({
                users: users
            })
        }, (error) => {
            Swal.fire({
                title: 'Error!',
                text: `${parseErrorResponse(error)}`,
                icon: 'error',
                confirmButtonText: 'Ok'
            }).then(r => console.log(r))
        }, () => {
            this.setState({loading: false});
        });

    }

    handleAddUser = e => {
        e.preventDefault();
        $('#addUserModal').modal('show');
    }

    handleDelete = user => {
        this.setState({
            loadingDelete: true,
            current_id: user.id
        });
        makeRequest(PATCH_REQUEST, `${endpoints.users}${user?.id}/`, {active: !user.active}, () => {
            this.getUsers();
        }, error => {
            showAlert('error', 'Error', parseErrorResponse(error))
        }, () => {
            this.setState({
                loadingDelete: false
            })
        })
    }

    render() {
        const {loading, users} = this.state;
        return (loading ?
                <div className="text-center">
                    <CircularProgress/>
                </div> :
                <div>
                    <UserAdd getUsers={this.getUsers}/>
                    <Table
                        caption={"Users"}
                        actionText={"Add User"}
                        columns={columns}
                        actionFunc={this.handleAddUser}
                        data={users}
                    />
                </div>
        );
    }
}

export default UsersList;