import React from "react";
import {Link} from "react-router-dom";
import {Notification} from "../../components/main/sidebar";
import {toDateTimeString} from "../../helpers/helper_functions";

export const messageColumns = [
    {
        dataField: 'name',
        text: 'Name',
        formatter: (name, row) => <p>{name} {!row.read && <Notification/>}</p>
    },
    {
        dataField: 'phone_number',
        text: 'Phone Number',
        //formatter: numberPlate => `${numberPlate || "-"}`
    },
    {
        dataField: 'email',
        text: 'Email',
        //formatter: numberPlate => `${numberPlate || "-"}`
    },
    {
        dataField: 'subject',
        text: 'Subject',
    },
    {
        dataField: 'created',
        text: 'Created',
        formatter: created => created ? toDateTimeString(created) : "-"
    },
    {
        dataField: 'id',
        text: 'Action',
        formatter: id => <Link to={`/messages/${id}`} className="btn btn-sm btn-success">View</Link>
    },
];