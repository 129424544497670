import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {connect} from "react-redux";
import {getStatistics} from "../../redux/statistics/actions";

const mapStateToProps = state => (state.statisticsState);

const mapDispatchToProps = dispatch => ({
    getStatistics: () => dispatch(getStatistics()),
});

class Totals extends React.Component {
    state = {
        loading: false,
        statistics: []
    }

    componentDidMount() {
        if (this.props.statistics == null)
            this.props.getStatistics();
    }

    render() {
        const {
            loading,
            statistics
        } = this.props;
        return (
            loading ?
                <div className="text-center">
                    <CircularProgress/>
                </div> :
                <div className="col-12">
                    <div className="row">
                        <div className="col-xl-3 col-md-6">
                            <div className="card mb-4 bg-purple">
                                <div className="card-body">
                                    <div className="media d-flex align-items-center ">
                                        <div className="mr-4 rounded-circle bg-white sr-icon-box text-purple">
                                            <span style={{fontSize: "2rem"}} className="ti-car"/>
                                        </div>
                                        <div className="media-body text-light">
                                            <h4 className="text-uppercase mb-0 weight500">{statistics?.total_vehicles}</h4>
                                            <span>Total Vehicles</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="card mb-4 bg-primary">
                                <div className="card-body">
                                    <div className="media d-flex align-items-center">
                                        <div className="mr-4 rounded-circle bg-white  sr-icon-box text-primary">
                                            <span className="material-icons">
                                                directions_car
                                            </span>
                                        </div>
                                        <div className="media-body text-white">
                                            <h4 className="text-uppercase mb-0 weight500">{statistics?.sold_cars}</h4>
                                            <span>Sold Cars</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="card mb-4 bg-danger">
                                <div className="card-body">
                                    <div className="media d-flex align-items-center">
                                        <div className="mr-4 rounded-circle bg-white sr-icon-box text-danger">
                                            <span style={{fontSize: "1.5rem"}} className="ti-clipboard"/>
                                        </div>
                                        <div className="media-body text-white">
                                            <h4 className="text-uppercase mb-0 weight500">{statistics?.available_cars}</h4>
                                            <span>Available Cars</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="card mb-4 bg-success">
                                <div className="card-body">
                                    <div className="media d-flex align-items-center">
                                        <div className="mr-4 rounded-circle bg-white sr-icon-box text-success">
                                            <i className="icon-people"/>
                                        </div>
                                        <div className="media-body text-white">
                                            <h4 className="text-uppercase mb-0 weight500">{statistics?.clients}</h4>
                                            <span>Clients</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="card mb-4 bg-warning">
                                <div className="card-body">
                                    <div className="media d-flex align-items-center">
                                        <div className="mr-4 rounded-circle bg-white sr-icon-box text-danger">
                                            <span style={{fontSize: "1.5rem"}} className="ti-info"/>
                                        </div>
                                        <div className="media-body text-white">
                                            <h4 className="text-uppercase mb-0 weight500">
                                                {statistics?.unchecked_enquiries}</h4>
                                            <span>Unchecked Enquiries</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="card mb-4 bg-info">
                                <div className="card-body">
                                    <div className="media d-flex align-items-center">
                                        <div className="mr-4 rounded-circle bg-white sr-icon-box text-success">
                                            <i className="ti-email"/>
                                        </div>
                                        <div className="media-body text-white">
                                            <h4 className="text-uppercase mb-0 weight500">
                                                {statistics?.unread_messages}</h4>
                                            <span>Unread Messages</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Totals);