import React, {Component} from 'react';
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST, PATCH_REQUEST, POST_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import Swal from "sweetalert2";
import {handleChange} from "../../../helpers/helper_functions";

const $ = window.$;

class FeaturesAdd extends Component {

    state = {
        name: '',
        loading: false,
        errors: {}
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.id !== this.props.id)
            this.getFeatureDetails(this.props.id);
    }

    getFeatureDetails = id => {
        this.setState({loading: true})
        makeRequest(GET_REQUEST, `${endpoints.features}${id}/`, {}, response => {
            this.setState({
                name: response.data.name
            })
        }, null, () => this.setState({loading: false}))
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        })
        const {name} = this.state;
        const id = this?.props?.id;
        const method = id ? PATCH_REQUEST : POST_REQUEST;
        const url = id ? `${endpoints.features}${id}/` : endpoints.features;
        makeRequest(method, url, {name: name}, () => {
            Swal.fire(
                'Success!',
                `Feature was ${id ? "edited" : "saved"} successfully!`,
                'success'
            ).then(() => {
                this.props.getFeatures();
                $("#addFeatureModal").modal('toggle');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
            });
        }, (error) => {
            this.setState({
                errors: error.response.data
            })
        }, () => {
            this.setState({loading: false})
        })
    }

    render() {
        const {errors, loading, name} = this.state;
        return (
            <div className="modal fade" id="addFeatureModal" role="dialog" aria-labelledby="exampleModalCenterTitle"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Add Feature</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Feature Name</label>
                                        <input
                                            defaultValue={name}
                                            onChange={(e) => handleChange(e, this)} type="text"
                                            className="form-control" name="name"/>
                                        {errors.name && <p className="small text-danger">{errors.name[0]}</p>}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button onClick={this.handleSubmit} type="button" className="btn btn-primary"
                                    disabled={loading}>{loading ? "Loading..." : "Save"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default FeaturesAdd;