import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../helpers/helper_functions";

export const FETCH_MAKES = "fetch_locations";
export const LOADING_MAKES = "loading_makes";

export const getMakes = () => {
    return dispatch => {
        dispatch({
            type: LOADING_MAKES
        })
        makeRequest(GET_REQUEST, `${endpoints.makes}`, {}, response => {
            const makes = response.data;
            return dispatch({
                makes: makes,
                type: FETCH_MAKES
            })
        }, error => {
            showAlert('error', "Error!", parseErrorResponse(error));
            console.log(error);
        });
    }
}