import React, {Component} from 'react';
import {connect} from "react-redux";
import {getMakes} from "../../../redux/makes/actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../../components/Table";
import MakesAdd from "./add";

const $ = window.$;

const mapStateToProps = state => (state.makesState);

const mapDispatchToProps = dispatch => ({
    getMakes: () => dispatch(getMakes()),
});

const columns = [
    {
        dataField: 'id',
        text: 'ID',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
    {
        dataField: 'name',
        text: 'Name',
        //  formatter: row => `${row.first_name} ${row.last_name}`
    },
]


class Makes extends Component {
    componentDidMount() {
        const {getMakes, makes} = this.props;
        if (makes == null) getMakes();
    }

    handleAddMake = e => {
        e.preventDefault();
        $('#addMakeModal').modal('show');
    }

    render() {
        const {loading, makes} = this.props;
        return (loading ?
                <div className="text-center">
                    <CircularProgress/>
                </div> :
                <div>
                    <MakesAdd getMakes={this.props.getMakes}/>
                    <Table
                        caption={"Makes"}
                        actionText={"Add Make"}
                        columns={columns}
                        actionFunc={this.handleAddMake}
                        data={makes}
                    />
                </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Makes);