import React, {Component} from 'react';
import endpoints from "../../routes/endpoints";
import {onMount} from "../../providers/loader_plain";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../components/Table";
import {enquiryColumns} from "../../providers/enquiries/data";
import {search} from "../../providers/search/loader";

class Enquiries extends Component {
    state = {
        enquiries: []
    }

    componentDidMount() {
        this.getEnquiries();
    }

    getEnquiries = () => {
        onMount(this, endpoints.enquiries, "enquiries");
    }

    searchAction = (value) => {
        search(`${endpoints.enquiries}`, value, this,"enquiries");
    }

    render() {
        let {loading, enquiries,loadingSearch} = this.state;

        return (
            loading ?
                <div className="text-center my-5 py-5">
                    <CircularProgress/>
                </div> :
                <div className="card">
                    <div className="card-body">
                        <Table
                            caption={"All Enquiries"}
                            hideAction={true}
                            columns={enquiryColumns()}
                            data={enquiries}
                            loadingSearch={loadingSearch}
                            searchAction={this.searchAction}
                        />
                    </div>
                </div>
        );
    }
}

export default Enquiries;